import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function AnnaHummingbirddetail() {
  const sliderRef = useRef(null);


  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Bird</p>
      </div>


      {/* add video, add video, add video */}

      <video controls width="640px" height="360px">
        <source src='https://player.vimeo.com/progressive_redirect/playback/700276827/rendition/540p?loc=external&oauth2_token_id=57447761&signature=9aeee9b5cdf5d3655fd06d0ac46d5915feb0f60d266e7944da0f8ca92b8a6f37' type="video/mp4" />
      </video>


      {/* img img img img img img */}

      {/* <img className='fiximg' src={fiximage} alt='img' /> */}

      <div className='spic-box'>
        <div>

          <p><b>Scientific Name:</b> Calypte anna</p><br />
          <p><b>Common Name    :</b> Anna's Hummingbird</p><br />
          <p><b>life Span      :</b> Up to 8 years in the wild</p><br />
          <p><b>Diet           :</b> Nectar from flowers,<br /> insects and spiders</p><br />
          <p><b>Size           :</b> 9-10 cm (3.5-4 inches) in length</p><br />
          <p><b>Weight         :</b>  3-6 grams (0.1-0.2 ounces)</p><br />
          <p><b>Species        :</b> Calypte anna is a member of the hummingbird family Trochilidae</p><br />
          <p><b>Group   :</b> Birds</p>
        </div>

        {/* circle img circle img circle img */}

        <img className='spic-img' src='https://cdn.pixabay.com/photo/2021/01/09/16/16/annas-hummingbird-5902858__340.jpg' alt='animal pic' />
      </div>

      <div className='detailboxx'>

        {/* animal name h2 */}

        <h2>Anna Hummingbird</h2><br />
        <p>
          Anna's hummingbirds, also known as Calypte anna, are a type of hummingbird native to<br /> the western coast of North America. They are known for their vibrant colors,<br /> quick movements, and unique characteristics. In this article, we will explore<br /> the world of Anna's hummingbirds, including their habitat, behavior,<br /> diet, lifespan, and other interesting facts.<br />

          <h3>Most common humming bird:</h3><br />
          Anna's hummingbirds are one of the most common hummingbirds found in North America. They can be <br />found along the western coast, from southern Alaska to Baja California. They prefer to live in a<br /> range of habitats, from coastal regions to mountainous areas. They are most commonly found in<br /> urban and suburban areas, where they can feed on the nectar from flowers and feeders.<br />

          <h3>fascinating aspects:</h3><br />
          One of the most fascinating aspects of Anna's hummingbirds is their unique behavior. They are known<br /> for their aggressive territorial behavior, especially during the breeding season. Males<br /> will aggressively defend their territory, which can be as small as a single tree or bush.<br /> They will also perform elaborate courtship displays, including diving and swooping flights and<br /> chirping calls.<br />

          <h3>Diet:</h3><br />
          Anna's hummingbirds have a specialized diet, consisting mostly of nectar from flowers. They are also<br /> known to eat insects and spiders, which provide them with protein and other nutrients.<br /> They have a high metabolism and need to consume large amounts of food to maintain their energy <br />levels. In fact, their heart rate can reach up to 1,260 beats per minute, making them one of the<br /> fastest-beating hearts of any animal.<br />

          <h3>Lifespan:</h3><br />
          Anna's hummingbirds have a relatively long lifespan for a bird of their size. They can live up to 8<br /> years in the wild and up to 12 years in captivity. They typically mate during the breeding<br /> season, which occurs from January to May. Females will build a small nest made of spider webs,<br /> lichens, and plant fibers. The nest is about the size of a golf ball and is usually located in a<br /> tree or shrub.<br />

          <h3>Total humming birds:</h3><br />
          Anna's hummingbirds are not the only type of hummingbird found in North America. There are over 300<br /> different species of hummingbirds worldwide, with 16 species found in North America. The<br /> rufous hummingbird, calliope hummingbird, Allen's hummingbird, and Costa's hummingbird are just a <br />few examples of the different types of hummingbirds found in North America.<br />

          Ruby-throated hummingbirds, found in the eastern United States, are known for their distinctive <br />call. They are the only hummingbird species found in eastern North America. The male has a vibrant<br /> ruby-red throat, while the female has a white throat. They have a wingspan of about 4<br /> inches and weigh less than a penny.<br />

          <h3>Humming bird known as striking colors:</h3><br />
          Hummingbirds are also known for their striking colors. Anna's hummingbirds have a bright green back<br /> and head, with a pinkish-red throat patch in males. Females have a green back and head,<br /> with a greyish-white throat. Hummingbirds have specialized feathers that reflect light, creating a<br /> shimmering effect.<br />

          <h3>Appearance:</h3><br />
          Despite their small size, hummingbirds have their fair share of predators. Snakes, birds of prey,<br /> and even domestic cats are known to prey on hummingbirds. Hummingbirds have a quick and agile<br /> flight, which allows them to escape from predators. They are also known to camouflage their nests to<br /> avoid detection.<br />

          <h3>Conclusion:</h3><br />
          In conclusion, Anna's hummingbirds are a fascinating and unique species of bird. Their vibrant<br /> colors, quick movements, and aggressive behavior make them a popular sight in many North American<br /> gardens. Whether you are a bird enthusiast or just appreciate the beauty of nature, Anna's <br />hummingbirds are sure to captivate your attention.<br />

        </p>
      </div>

      {/* slider code */}

      <div className="slider-container">
        <button className="prev-button" onClick={handlePrev}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
        </button>
        <div className="slider" ref={sliderRef}>
          <img src="https://i.pinimg.com/originals/0c/18/98/0c1898a2bafeeb398f32e055cbed69b8.jpg" alt="Image 1" className="slider-image" />
          <img src="https://www.birdnote.org/sites/default/files/17-female-annas-1b.jpg" alt="Image 2" className="slider-image" />
          <img src="https://celebrateurbanbirds.org/wp-content/uploads/2018/07/shutterstock_395179858.jpg" alt="Image 3" className="slider-image" />
          <img src="https://www.birdnote.org/sites/default/files/18-female-annas-2b.jpg" alt="Image 4" className="slider-image" />
          <img src="https://www.sdakotabirds.com/species_photos/hummingbirds/annas_hummingbird_10.jpg" alt="Image 5" className="slider-image" />
          <img src="https://i.redd.it/hupcdi9swso01.jpg" alt="Image 6" className="slider-image" />
          <img src="https://i.pinimg.com/originals/6e/b7/b6/6eb7b6eebfc6a701feaaead78b34bac4.jpg" alt="Image 7" className="slider-image" />
          <img src="https://www.birdsandblooms.com/wp-content/uploads/2021/04/BNBbyc19_elisa-taylor-godwin.jpg?w=1200" alt="Image 8" className="slider-image" />
        </div>
        <button className="next-button" onClick={handleNext}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
        </button>

      </div>

    </>
  );
}
export default AnnaHummingbirddetail;