import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/Aardvark.png';
import { Link } from 'react-router-dom';

function Aardvarkdetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            {/* <video controls width="640px" height="360px">
                <source src='https://player.vimeo.com/external/543813393.sd.mp4?s=572f8f8be0239cca200a348bb4722491f98a400a&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video> */}


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b> Aardvark Orycteropus afer</p><br />
                    <p><b>Common Name    :</b> Aardvark</p><br />
                    <p><b>life Span      :</b> 18-23 years</p><br />
                    <p><b>Diet           :</b> Insectivore</p><br />
                    <p><b>Size           :</b> Head and body: 43 to 53 inches;</p><br />
                    <p><b>Weight         :</b> 110 to 180 pounds</p><br />
                    <p><b>Species        :</b> The aardvark (Orycteropus afer) is a mammal species native to Africa. It is the only living species in the family Orycteropodidae and order Tubulidentata.</p><br />
                    <p><b>Animal Group   :</b> "canteen" or an "armory" of aardvarks</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://media.istockphoto.com/id/120705958/photo/aardvark-orycteropus-sixteen-years-old-walking-white-background.jpg?s=612x612&w=0&k=20&c=1W_mNVXhs6bo1Vl3a3BnU7mnCIvKSzZaCvV2EbtDnMk=' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Aardvark</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}

                    The Aardvark is a fascinating animal with a unique appearance and many interesting<br /> characteristics. In this article, we'll explore the meaning of the word "aardvark," some<br /> basic animal facts, as well as some interesting facts about aardvarks and their<br /> habitat, food, and behavior. We'll also take a look at some amazing animals<br /> around the world, including the aardvark, and discuss whether or not it's possible to<br /> have an aardvark as a pet.<br /><br />

                    <h3>Basic information about Aardvark:</h3><br />

                    Let's start with the basics: the aardvark is a nocturnal mammal native to Africa. Its name<br /> comes from the Afrikaans words "aard," meaning earth, and "vark," meaning pig.<br /> Despite this name, the aardvark is not closely related to pigs, and is actually the<br /> only living species in its order, Tubulidentata.<br /><br />

                    <h3>Facts about Aardvark:</h3><br />

                    Aardvarks are known for their long, narrow snouts, which they use to sniff out their<br /> favorite foods: ants and termites. <br />These animals have long, sticky tongues that can<br /> extend up to a foot and a half, making them perfect for capturing insects. In<br /> addition to their unique snouts and tongues, aardvarks also have powerful claws that<br /> they use to dig into the ground and create burrows where they can sleep during the day.<br /><br />

                    <h3>characteristics of Aardvark:</h3><br />


                    Aardvarks are fascinating creatures with many interesting characteristics. For example,<br /> they have very thick skin, which protects them from ant bites and other insect stings.<br /> They also have very strong muscles in their tails, which they can use to defend<br /> themselves from predators. Despite their tough exterior, however, aardvarks are not<br /> aggressive animals, and will usually try to flee from danger rather than fight. <br /><br />

                    <h3>habitat:</h3><br />

                    In terms of habitat, aardvarks are found throughout sub-Saharan Africa, where they live in<br /> a variety of different environments, including grasslands, savannas, and forests.<br /> <br /> They are also capable of surviving in semi-arid regions, as long as there is<br /> enough vegetation to support the ants and termites that they feed on.<br /><br />

                    <h3>Diet:</h3><br />


                    When it comes to food, aardvarks are strict insectivores, and will eat nothing but ants<br /> and termites. They have been known to consume up to 50,000 insects in a single night,<br /> making them incredibly efficient hunters. Aardvarks are also able to survive without<br /> water for long periods of time, getting all the moisture they need from their food.<br /><br />

                    <h3>Reproduction:</h3><br />

                    Aardvarks reproduce sexually and have a polygynous mating system, where a single male<br /> mates with multiple females. They do not have a specific breeding season and can reproduce<br /> year-round. <br /><br />

                    <h3>Interesting Fact:</h3><br />

                    One of the most interesting facts about aardvarks is that they are not closely related to<br /> any other animal species. In fact, scientists believe that aardvarks may be one of<br /> the oldest mammals on earth, with a history that dates back over 60 million years.<br /> Despite their ancient origins, however, aardvarks remain one of the most fascinating<br /> and unique animals on the planet.<br /><br />

                    <h3>
                        But what about the aardvark?<br /> Is it possible to have one of these creatures as a pet? </h3><br />
                    The answer is no, aardvarks are not suitable as pets. They are wild animals that require<br /> specialized care and an environment that is very different from a typical home.<br /><br />

                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Antelope-detail">1<img src="https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alligator-detail">2<img src="https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Armadillo-detail">3<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">4<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">5<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">6<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">7<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">8<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://wallpapercave.com/wp/wp2823719.jpg" alt="Image 1" className="slider-image" />
                    <img src="https://gothamist.com/attachments/arts_jen/aardvark1110a.jpg" alt="Image 2" className="slider-image" />
                    <img src="https://vignette.wikia.nocookie.net/animals-animals/images/4/4f/T17_0055_010.jpg/revision/latest?cb=20180929214558" alt="Image 3" className="slider-image" />
                    <img src="https://wanderlord.com/wp-content/uploads/2016/12/aardvark-6.jpg" alt="Image 4" className="slider-image" />
                    <img src="https://kidssearch.com/picsearch/images/aardvark-pic-1600x1086-b086314.png" alt="Image 5" className="slider-image" />
                    <img src="https://cdn1-www-webecoist.momtastic.com/assets/uploads/2015/06/wenn21704940.jpg" alt="Image 6" className="slider-image" />
                    <img src="https://www.zooborns.com/.a/6a010535647bf3970b01a3fccc973e970b-800wi" alt="Image 7" className="slider-image" />
                    <img src="https://c1.staticflickr.com/4/3732/9975218546_ce20af0739.jpg" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Aardvarkdetail;