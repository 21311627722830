import React, { createContext, useState } from "react";
import Products from "./BProducts";
import goldfinch from './voice/goldfinch.mp3';
import Alder from './voice/Alder-Flycatcher.mp3';
import AmeCrow from './voice/American-Crow.mp3';
import AmeKestrel from './voice/American-Kestrel.mp3';
import Anna from './voice/Anna-Humming.mp3';
import Arctic from './voice/Arctic-Tern.mp3';
import Atlantic from './voice/Atlantic-Puffin.mp3';
import Magpie from './voice/magpie.mp3';
import robin from './voice/robin.mp3';
import { Helmet } from "react-helmet-async";


export const ProductsContext = createContext();

const BirdsContextProvider = (props) => {
    const [products] = useState([
        {
            id: 1,
            name: 'Gold Finch',
            image: "https://images.pexels.com/photos/12755522/pexels-photo-12755522.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: goldfinch,
        },
        {
            id: 2,
            name: 'American Robin',
            image: "https://cdn.pixabay.com/photo/2017/03/08/15/01/american-robin-2126986__340.jpg",
            audio: robin,
        },
        {
            id: 3,
            name: 'Arctic Tern',
            image: "https://cdn.pixabay.com/photo/2017/01/30/14/32/arctic-tern-2020952__340.jpg",
            audio: Arctic,

        },
        {
            id: 4,
            name: "Anna Hummingbird",
            image: "https://cdn.pixabay.com/photo/2021/03/10/12/48/annas-hummingbird-6084490__340.jpg",
            audio: Anna,
        },
        {
            id: 5,
            name: 'Atlantic Puffin',
            image: "https://images.unsplash.com/photo-1569579933032-9e16447c50e3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fHB1ZmZpbnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
            audio: Atlantic,
        },
        {
            id: 6,
            name: 'Australian Magpie',
            image: "https://cdn.pixabay.com/photo/2020/01/13/20/53/black-and-white-4763544__340.jpg",
            audio: Magpie,
        },
        {
            id: 7,
            name: 'American Kestrel',
            image: "https://media.istockphoto.com/id/1432152261/photo/kestrel.jpg?s=612x612&w=0&k=20&c=axmlq6s0ZnGSwoUWmr-gJlgTstTy3HMr_WNJP9U9ft0=",
            audio: AmeKestrel,
        },
        {
            id: 8,
            name: 'American Crow',
            image: "https://images.pexels.com/photos/8168900/pexels-photo-8168900.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: AmeCrow,
        },
        {
            id: 9,
            name: 'Alder Flycatcher',
            image: "https://media.istockphoto.com/id/1432936923/photo/willow-flycatcher-perched-on-a-tig.jpg?s=612x612&w=0&k=20&c=Av-iWQWgZIntC4-5Bheni-qKt--vfXD2HtVg3v0qlXo=",
            audio: Alder,
        },
        {
            id: 10,
            name: 'Bald Eagle',
            image: "https://cdn.pixabay.com/photo/2017/06/09/09/39/adler-2386314_640.jpg",
            audio: "",
        },
        {
            id: 11,
            name: 'Baltimore Oriole',
            image: "https://images.pexels.com/photos/12755515/pexels-photo-12755515.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: "",
        },
        {
            id: 12,
            name: 'Barn Owl',
            image: "https://cdn.pixabay.com/photo/2020/12/15/02/06/barn-owl-5832425_640.jpg",
            audio: "",
        },
        {
            id: 13,
            name: 'Barn Swallow',
            image: "https://cdn.pixabay.com/photo/2022/08/19/23/03/bird-7397839_640.jpg",
            audio: "",
        },
        {
            id: 14,
            name: 'Barred Owl',
            image: "https://images.pexels.com/photos/6152684/pexels-photo-6152684.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: "",
        },
        {
            id: 15,
            name: 'Baya Weaver',
            image: "https://cdn.pixabay.com/photo/2019/09/19/17/00/bird-4489775_640.jpg",
            audio: "",
        },
        {
            id: 16,
            name: 'Bearded Barbet',
            image: "https://st.depositphotos.com/1292828/3478/i/450/depositphotos_34783239-stock-photo-bearded-barbet-lybius-dubius-an.jpg",
            audio: "",
        },
        {
            id: 17,
            name: 'Bearded Reedling',
            image: "https://st5.depositphotos.com/77655184/64611/i/450/depositphotos_646112078-stock-photo-bearded-reedling-panurus-biarmicus-male.jpg",
            audio: "",
        },
        {
            id: 18,
            name: 'Belted Kingfisher',
            image: "https://st4.depositphotos.com/18012752/25316/i/450/depositphotos_253162996-stock-photo-belted-kingfisher-portrait-pennsylvania.jpg",
            audio: "",
        },
        {
            id: 19,
            name: 'Bicolored Antbird',
            image: "https://st4.depositphotos.com/1011590/20358/i/450/depositphotos_203589362-stock-photo-close-bicolored-antbird-perched-tree.jpg",
            audio: "",
        },
        {
            id: 20,
            name: 'Black Skimmer',
            image: "https://st3.depositphotos.com/7402484/19070/i/450/depositphotos_190703720-stock-photo-black-skimmer-flying-over-river.jpg",
            audio: "",
        },
        {
            id: 21,
            name: 'Black Swan',
            image: "https://static3.depositphotos.com/1005657/220/i/450/depositphotos_2203067-stock-photo-black-swans.jpg",
            audio: "",
        },
        {
            id: 22,
            name: 'Black Tern',
            image: "https://st3.depositphotos.com/31225486/37249/i/450/depositphotos_372491934-stock-photo-inca-tern-larosterna-inca-sitting.jpg",
            audio: "",
        },
        {
            id: 23,
            name: 'Black Vulture',
            image: "https://st4.depositphotos.com/18673118/24477/i/450/depositphotos_244778252-stock-photo-cinereous-vulture-aegypius-monachus-standing.jpg",
            audio: "",
        },
        {
            id: 24,
            name: 'Black-and-white Warbler',
            image: "https://cdn.pixabay.com/photo/2022/04/25/13/01/bird-7155898_640.jpg",
            audio: "",
        },
        {
            id: 25,
            name: 'Black-backed Woodpecker',
            image: "https://st4.depositphotos.com/24230416/25426/i/450/depositphotos_254268566-stock-photo-white-backed-woodpecker-female-on.jpg",
            audio: "",
        },
        {
            id: 26,
            name: 'Black-bellied Whistling-Duck',
            image: "https://cdn.pixabay.com/photo/2022/03/13/22/09/birds-7066902_640.jpg",
            audio: "",
        },
        {
            id: 27,
            name: 'Black-capped Chickadee',
            image: "https://static7.depositphotos.com/1313859/791/i/450/depositphotos_7916765-stock-photo-chickadee-on-a-branch.jpg",
            audio: "",
        },
        {
            id: 28,
            name: 'Black-collared Hawk',
            image: "https://st5.depositphotos.com/22386024/64583/i/450/depositphotos_645839752-stock-photo-black-collared-hawk-busarellus-nigricollis.jpg",
            audio: "",
        },
        {
            id: 29,
            name: 'Black-crested Bulbul',
            image: "https://st5.depositphotos.com/14603326/64851/i/450/depositphotos_648514900-stock-photo-beautiful-black-crested-bulbul-perched.jpg",
            audio: "",
        },
        {
            id: 30,
            name: 'Black-crowned Night Heron',
            image: "https://st2.depositphotos.com/15503194/46400/i/450/depositphotos_464001420-stock-photo-black-crowned-night-heron-standing.jpg",
            audio: "",
        },
        {
            id: 31,
            name: 'Black-faced Spoonbill',
            image: "https://st2.depositphotos.com/23244832/42528/i/450/depositphotos_425287052-stock-photo-exotic-bird-black-faced-spoonbill.jpg",
            audio: "",
        },
        {
            id: 32,
            name: 'Black-footed Albatross',
            image: "https://st.depositphotos.com/1010710/3389/i/450/depositphotos_33897057-stock-photo-black-footed-albatross-feeding-nestling.jpg",
            audio: "",
        },
        {
            id: 33,
            name: 'Black-headed Gull',
            image: "https://st2.depositphotos.com/1297731/45033/i/450/depositphotos_450331854-stock-photo-black-headed-gull-fishing-lake.jpg",
            audio: "",
        },
        {
            id: 34,
            name: 'Black-headed Heron',
            image: "https://cdn.pixabay.com/photo/2018/03/05/20/47/grey-heron-3201771_640.jpg",
            audio: "",
        },
        {
            id: 35,
            name: 'Black-headed Weaver',
            image: "https://st4.depositphotos.com/1417133/23531/i/450/depositphotos_235319382-stock-photo-black-headed-bunting-granativora-melanocephala.jpg",
            audio: "",
        },
        {
            id: 36,
            name: 'Black-necked Aracari',
            image: "https://static8.depositphotos.com/1160062/969/i/450/depositphotos_9698166-stock-photo-black-necked-aracari-sitting-on.jpg",
            audio: "",
        },
        {
            id: 37,
            name: 'Black-necked Grebe',
            image: "https://st3.depositphotos.com/9643694/12744/i/450/depositphotos_127448926-stock-photo-the-black-necked-grebe-birds.jpg",
            audio: "",
        },
        {
            id: 38,
            name: 'Black-winged Kite',
            image: "https://st5.depositphotos.com/42408962/64907/i/450/depositphotos_649076558-stock-photo-black-winged-kite-elanus-caeruleus.jpg",
            audio: "",
        },
        {
            id: 39,
            name: 'Blue Jay',
            image: "https://cdn.pixabay.com/photo/2021/09/30/18/08/blue-jay-6670703_640.jpg",
            audio: "",
        },
        {
            id: 40,
            name: 'Blue Tit',
            image: "https://st2.depositphotos.com/3588977/9773/i/450/depositphotos_97732870-stock-photo-beautiful-blue-tit.jpg",
            audio: "",
        },
        {
            id: 41,
            name: 'Blue-capped Tanager',
            image: "https://st5.depositphotos.com/35940402/65274/i/450/depositphotos_652740646-stock-photo-sooty-capped-bush-tanager-perched.jpg",
            audio: "",
        },
        {
            id: 42,
            name: 'Blue-faced Honeyeater',
            image: "https://st2.depositphotos.com/1828855/9089/i/450/depositphotos_90891800-stock-photo-honeyeater-birds-of-australia.jpg",
            audio: "",
        },
        {
            id: 43,
            name: 'Blue-footed Booby',
            image: "https://st2.depositphotos.com/1702382/11533/i/450/depositphotos_115335648-stock-photo-blue-footed-booby-on-north.jpg",
            audio: "",
        },
        {
            id: 44,
            name: 'Blue-gray Gnatcatcher',
            image: "https://static7.depositphotos.com/1313859/791/i/450/depositphotos_7918775-stock-photo-blue-gray-gnatcatcher.jpg",
            audio: "",
        },
        {
            id: 45,
            name: 'Blue-throated Bee-eater',
            image: "https://st2.depositphotos.com/3141295/47321/i/450/depositphotos_473212470-stock-photo-flock-beautiful-green-blue-chestnut.jpg",
            audio: "",
        },
        {
            id: 46,
            name: 'Blue-winged Kookaburra',
            image: "https://st4.depositphotos.com/32264076/39250/i/450/depositphotos_392501880-stock-photo-blue-winged-kookaburra-bird-sitting.jpg",
            audio: "",
        },
        {
            id: 47,
            name: 'Boat-billed Heron',
            image: "https://st3.depositphotos.com/15930984/18432/i/450/depositphotos_184329366-stock-photo-a-boat-billed-heron-cochlearius.jpg",
            audio: "",
        },
        {
            id: 48,
            name: 'Bobolink',
            image: "https://st4.depositphotos.com/3153353/23537/i/450/depositphotos_235372066-stock-photo-male-bobolink-dolichonyx-oryzivorus-post.jpg",
            audio: "",
        },
        {
            id: 49,
            name: 'Bohemian Waxwing',
            image: "https://st3.depositphotos.com/9643694/15228/i/450/depositphotos_152281594-stock-photo-bohemian-waxwing-perched-on-a.jpg",
            audio: "",
        },
        {
            id: 50,
            name: 'Boreal Owl',
            image: "https://st2.depositphotos.com/7402484/10871/i/450/depositphotos_108716964-stock-photo-small-bird-boreal-owl.jpg",
            audio: "",
        },
        {
            id: 51,
            name: 'Brazilian Tanager',
            image: "https://st2.depositphotos.com/3669029/11131/i/450/depositphotos_111319898-stock-photo-brazilian-tanager-ramphocelus-bresilius.jpg",
            audio: "",
        },
        {
            id: 52,
            name: 'Brewers Blackbird',
            image: "https://st5.depositphotos.com/1046403/65446/i/450/depositphotos_654460774-stock-photo-brewer-blackbird-perched-wooden-post.jpg",
            audio: "",
        },
        {
            id: 53,
            name: 'Bridled Tern',
            image: "https://st3.depositphotos.com/13219218/18996/i/450/depositphotos_189969200-stock-photo-bridled-tern-isla-mujeres.jpg",
            audio: "",
        },
        {
            id: 54,
            name: 'Broad-billed Hummingbird',
            image: "https://st2.depositphotos.com/7402484/10296/i/450/depositphotos_102969654-stock-photo-flying-blue-and-white-hummingbird.jpg",
            audio: "",
        },



    ]);

    return (
        <>
            <Helmet>
                <title>Birds</title>
                <meta name='description' content='THE NATURAL WORLD birds page'></meta>
                <link rel='canonical' href='/BProductsContext' />
            </Helmet>
            <ProductsContext.Provider value={{ products: [...products] }}>
                {props.children}
                <Products />
            </ProductsContext.Provider>
        </>

    )
}

export default BirdsContextProvider;


