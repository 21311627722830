import React from 'react';
function Underconstruction(){
    return(
        <>
                <h1 style={{color:"red",textAlign:"center"}}>This WEB-PAGE is UNDERCONSTRUCTION</h1>
                <a href="https://thenaturalworld.net/AProductsContext" target="_blank" rel="noopener noreferrer">Go to Example.com</a>


        </>
    );
}
export default Underconstruction;