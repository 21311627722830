import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ProductsContext } from "./AAProductsContext";
import Slider from "./See-animals-Slider/SeeSlider";

const Products = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const { products } = useContext(ProductsContext);

    const navigate = useNavigate();

    function detail(id) {
        if (id === 1) {
            navigate('/Antelope-detail');
        }
        else {
            navigate('/');
        }

    }

    return (
        <>
            <Slider/>
            <div className='container'>

                <div className="galleryH">

                    <div className="gallery">
                        <img src={process.env.PUBLIC_URL + `/icon/icon1-gallery.png`} style={{ marginRight: '20px', marginLeft: '20px' }} />
                        <h1>Aquatic <span>Animals</span></h1>
                    </div>

                    <div className="searchbar">
                        <input id="searchInput" type="text" placeholder="Search any Animal here..." onChange={(event) => {
                            setSearchTerm(event.target.value);
                        }} />
                    </div>

                </div>

                <div className="products">

                    {products

                        .filter((product) => {
                            if (searchTerm === "") {
                                return product;
                            } else if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return product;
                            }
                        })

                        .map((product) => {
                            return (
                                <div className="product" key={product.id}>

                                    <div className="product-image">
                                        <img src={product.image} alt="not found" />
                                    </div>

                                    <div className="product-name">
                                        {product.name}
                                    </div>


                                    <button className="detail-button" onClick={() => detail(product.id)}>
                                        View Detail </button>
                                </div>
                            )

                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Products;





