import React from 'react';
import { Link } from 'react-router-dom';
import Marquee from "react-fast-marquee";
import './style.css';

function Footer() {
    return (
        <footer className="footer">
            <div className='footcontainer'>
                <div className="flt">
                    <div className='logo'>
                        <img style={{margin: '5px' }} src={process.env.PUBLIC_URL + `/logo1.jpg`} alt='img' width={'100px'} height={'100px'}/>
                    </div>
                    <p>Copyright © 2023
                        <a href="#"> The Natural World </a>. All rights reserved.</p>
                </div>


                <div className="footer-nav">
                    <ol>
                        <li>
                            <Link to="/">Home</Link>
                        </li>
                        <li>
                            <Link to="/about">About Us</Link>
                        </li>
                        <li>
                            <Link to="/AProductsContext">Animals</Link>
                        </li>
                        <li>
                            <Link to="/BProductsContext">Birds</Link>
                        </li>
                    </ol>
                </div>


                <div>
                    <Marquee>
                        <div className='logo'>
                            <img style={{margin: '5px' }}
                                src={process.env.PUBLIC_URL + `/logo1.jpg`} alt='img' width={'45px'} height={'45px'}
                            />
                        </div>
                        The Natural World.
                    </Marquee>

                </div>

            </div>
        </footer>
    );
}

export default Footer;
