import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/Anaconda.png';
import { Link } from 'react-router-dom';


function Anacondadetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src='https://player.vimeo.com/external/405379268.sd.mp4?s=4f09579780757198193e6ffa69c16ce7961afeb4&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b>Eunectes murinus</p><br />
                    <p><b>Common Name    :</b> Anaconda</p><br />
                    <p><b>life Span      :</b> 10 years</p><br />
                    <p><b>Diet           :</b> Carnivores</p><br />
                    <p><b>Size           :</b> 20 to 30 feet</p><br />
                    <p><b>Weight         :</b> up to 550 pounds</p><br />
                    <p><b>Species        :</b> green anaconda (Eunectes murinus)</p><br />
                    <p><b>Animal Group   :</b> Bed, knot</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://www.worldatlas.com/r/w1200/upload/79/23/30/shutterstock-1097222495.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Anaconda</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}

                    Anacondas are some of the largest snakes in the world,<br /> with the green anaconda being the largest of them all.<br /> The anaconda is a non-venomous snake,<br /> but its size and strength make it a formidable predator.<br /> There are several species of anacondas,<br /> but the green anaconda is the most well-known.<br /><br />

                    <h3>Green Anaconda:</h3><br />

                    The green anaconda is native to South America and can be found in the Amazon Basin,<br /> as well as in other parts of the continent.<br /> It can grow to be over 30 feet long and weigh up to 550 pounds,<br /> making it one of the largest snakes in the world. Despite its size,<br /> the green anaconda is an excellent swimmer and can move quickly through the water.<br /><br />

                    <h3>Difference between python and anaconda snake:</h3><br />

                    The anaconda snake is often confused with the python snake,<br /> but they are two different species.<br /> While anacondas are found in South America,<br /> pythons are found in Africa, Asia, and Australia. <br />The Burmese python is the largest species of python and can grow to be over 20 feet long.<br /><br />

                    <h3>About Giant Anaconda:</h3><br />

                    One of the most famous anaconda stories is that of the giant anaconda,<br /> which is said to be a mythical creature that is even larger than the green anaconda.<br /> While there is no evidence to support the existence of the giant anaconda,<br /> it is a popular legend in South America. <br /><br />

                    <h3>Size:</h3><br />

                    The size of the green anaconda can vary,<br /> with males being smaller than females.<br /> Males can grow to be up to 16 feet long,<br /> while females can reach over 30 feet.<br /> The anaconda snake size can also vary depending on the species,<br /> with some species being smaller than the green anaconda.<br /><br />

                    <h3>Length of Anaconda:</h3><br />

                    The length of the anaconda can also vary depending on the species.<br /> The green anaconda is known for its length, but other species of anaconda can be shorter.<br /> The yellow anaconda, <br />for example:<br /> is a smaller species that is found in South America.<br /><br />

                    <h3>Is Anaconda kept as a pet or not?</h3><br />

                    Anacondas are often kept as pets,<br /> but they require a lot of space and care. Green anaconda for sale can be found online,<br /> but it is important to make sure that <br />you are purchasing from a reputable breeder.<br /><br />

                    <h3>Fact About Anaconda:</h3><br />

                    Anaconda facts also include information about other types of snakes.<br /> The black snake is a common species in North America,<br /> while the water snake is found in many parts of the world.<br /> The most venomous snake in the world is the inland taipan,<br /> while the largest snake in the world is the reticulated python.<br /><br />

                    <h3>
                        Interesting Fact</h3><br />
                    Anaconda pictures and videos are popular online,<br /> and there are many interesting facts about the anaconda.<br /> For example:<br /> anacondas are known to be excellent swimmers and can stay underwater for up to 10 minutes.<br /><br />

                    <h3>
                        Weight</h3><br />
                    Anaconda images can be found online,<br /> and they are often used in educational materials about snakes.<br /> Anaconda weight can vary depending on the size of the snake,<br /> with some anacondas weighing over 500 pounds.<br /><br />

                    <h3>
                        Anaconda Biggest snake in the world</h3><br />
                    The biggest anaconda in the world is a subject of much debate,<br /> with many people claiming to have seen or caught the largest anaconda ever.<br /><br />
                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Antelope-detail">1<img src="https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alligator-detail">2<img src="https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Armadillo-detail">3<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Aardvark-detail">4<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">5<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">6<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">7<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">8<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://wgbh.brightspotcdn.com/dims4/default/ec78cdd/2147483647/strip/true/crop/5968x3255+10+741/resize/990x540!/quality/70/?url=https:%2F%2Fwgbh.brightspotcdn.com%2F76%2F68%2F07ad709e4687a68917c30cb73376%2Fdsc-0017.JPG" alt="Image 1" className="slider-image" />
                    <img src="https://www.wildrepublic.com/wp-content/uploads/2018/10/AnacondaSnake-1000x673-xl-768x517.jpg" alt="Image 2" className="slider-image" />
                    <img src="https://www.thegreenhead.com/imgs/giant-outdoor-anaconda-4.jpg" alt="Image 3" className="slider-image" />
                    <img src="https://nationalzoo.si.edu/sites/default/files/animals/greenanaconda-001.jpg" alt="Image 4" className="slider-image" />
                    <img src="https://otlibrary.com/wp-content/gallery/anaconda/green_anaconda_mouth.jpg" alt="Image 5" className="slider-image" />
                    <img src="https://www.washingtonpost.com/resizer/b_lDUBAwY0-2bC8RViKxPqMkLJk=/1440x0/smart/arc-anglerfish-washpost-prod-washpost.s3.amazonaws.com/public/4KLHA7FJ747ZXIE6HYJMJDB4ZM.jpg" alt="Image 6" className="slider-image" />
                    <img src="https://ak1.picdn.net/shutterstock/videos/10745591/thumb/1.jpg" alt="Image 7" className="slider-image" />
                    <img src="https://qph.fs.quoracdn.net/main-qimg-a9484b95be4bb827d40e6c81174f1f9c" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Anacondadetail;