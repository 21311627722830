import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function AmericanRobindetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
      const sliderInterval = setInterval(() => {
        if (sliderRef.current) {
          const slider = sliderRef.current;
          const sliderWidth = slider.offsetWidth;
          const currentScroll = slider.scrollLeft;
          const maxScroll = slider.scrollWidth - sliderWidth;
  
          if (currentScroll >= maxScroll) {
            // When reaching the last image, scroll back to the first image
            slider.scrollTo({
              left: 0,
              behavior: 'smooth',
            });
          } else {
            // Scroll to the next image
            slider.scrollBy({
              left: sliderWidth,
              behavior: 'smooth',
            });
          }
        }
      }, 5000);
  
      return () => {
        clearInterval(sliderInterval);
      };
    }, []);
  
    const handlePrev = () => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
  
        if (currentScroll === 0) {
          // When on the first image, scroll to the last image
          slider.scrollTo({
            left: slider.scrollWidth,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the previous image
          slider.scrollBy({
            left: -sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    };
  
    const handleNext = () => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - slider.offsetWidth;
  
        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    };



    
   return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Bird</p>
            </div>


            {/* add video, add video, add video */}

            <video controls width="640px" height="360px">
                <source src='https://player.vimeo.com/external/519799129.sd.mp4?s=b8f9af17a7af8717e2ef9c6df29c2388f685fc2f&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            {/* <img className='fiximg' src={fiximage} alt='img' /> */}

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b> Turdus migratorius</p><br />
                    <p><b>Common Name    :</b> American Robin</p><br />
                    <p><b>life Span      :</b>  2-6 years in the wild, up to 14 years in captivity</p><br />
                    <p><b>Diet           :</b> Omnivorous diet including insects, fruits, and berries</p><br />
                    <p><b>Size           :</b> 23-28 cm (9-11 inches) in lengthv</p><br />
                    <p><b>Weight         :</b> 72-95 grams (2.5-3.4 ounces)</p><br />
                    <p><b>Species        :</b> Turdus migratorius is a member of the thrush family Turdidae</p><br />
                    <p><b>Group   :</b> Birds</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://cdn.pixabay.com/photo/2023/04/23/16/01/bird-7946042__340.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>American Robin</h2><br />
                <p>
                The American Robin, also known simply as the Robin, is a beloved bird that is a<br/>common sight across North America. This popular bird is known for its distinctive<br/> red breast and cheerful song. In this article, we will explore the<br/> various aspects of the American Robin, including its appearance, behavior,<br/> habitat, and range.<br/>


                <h3>Appearance:</h3><br/>
The American Robin is a medium-sized bird, measuring about 23-28 cm in length and weighing around<br/> 77-85 grams. It has a distinctive red breast, a grayish-brown back, and a white throat and belly.<br/> The male Robin has a brighter red breast than the female, while the female has a paler<br/> coloring and a grayish crown. During the breeding season, the male Robin may also have a black head<br/> and a more prominent red breast.<br/>


<h3>Behavior:</h3><br/>
American Robins are active birds that are often seen hopping along lawns or feeding on worms and<br/> insects. They are also known for their sweet, melodious song, which is often heard in the early<br/> morning or at dusk. During the breeding season, American Robins build cup-shaped nests out of<br/> twigs, grass, and mud, usually in trees or shrubs. The female Robin lays 3-5 blue-green eggs, which<br/> hatch after about 12-14 days.<br/>


<h3>Habitat:</h3><br/>
American Robins are found across North America, from Canada to Mexico. They prefer open habitats<br/> such as lawns, fields, parks, and gardens, but can also be found in forests and woodlands. During<br/> the winter months, American Robins may gather in flocks and feed on berries and other<br/> fruits.<br/>


<h3>Range:</h3><br/>
The American Robin has a wide range across North America, with a breeding range that extends from<br/> Alaska and northern Canada to Mexico, and a winter range that extends further south into Central<br/> America. During migration, some American Robins may also be found in Hawaii and the <br/>Caribbean.<br/>


<h3>Nesting:</h3><br/>
If you are lucky enough to find an American Robin's nest in your yard, it's important to give the <br/>birds space and avoid disturbing them. American Robins are protected by law, and it's illegal<br/> to remove their nests or eggs. If you are interested in watching nesting robins,<br/> consider setting up a birdhouse or nesting platform in your yard.<br/>


<h3>Facts:</h3><br/>

1.The American Robin is the state bird of Connecticut, Michigan, and Wisconsin.<br/>
2.American Robins are sometimes called "worm-eating thrushes" because of their diet.<br/>
3.American Robins are one of the first birds to sing in the morning, often beginning their song before<br/> dawn.<br/>
4.American Robins are known to be monogamous, with pairs often staying together for multiple breeding<br/> seasons.<br/>
5.During the winter, American Robins may gather in large flocks and feed on berries and other fruits.<br/>

<h3>Identification:</h3><br/>
American Robins are easy to identify by their distinctive red breast and grayish-brown back. The<br/> female Robin has a paler coloring and a grayish crown, while the male has a brighter red breast<br/> and may have a black head during breeding season. American Robins also have a sweet, melodious<br/> song that is easy to recognize.<br/>

<h3>Conclusion:</h3><br/>
In conclusion, the American Robin is a beloved bird that is a common sight across North America. <br/>With its distinctive red breast, cheerful song, and lively behavior, it is a favorite of<br/> birdwatchers and nature lovers alike. Whether you encounter them in your yard, at a park, or in the<br/> wild, take a moment to appreciate the beauty of these wonderful birds and all the wonders<br/> of nature.<br/>

                </p>
            </div>

            {/* slider code */}

            <div className="slider-container">
          <button className="prev-button" onClick={handlePrev}>
            <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
          </button>
          <div className="slider" ref={sliderRef}>
            <img src="https://www.animalspot.net/wp-content/uploads/2011/08/American-Robin-Images.jpg" alt="Image 1" className="slider-image" />
            <img src="https://static.photocrowd.com/photos/4254735-8950570c13c200f130d066411f34944306c99de3-hd.jpeg" alt="Image 2" className="slider-image" />
            <img src="https://upload.wikimedia.org/wikipedia/commons/e/e4/American_Robin_4.jpg" alt="Image 3" className="slider-image" />
            <img src="https://live.staticflickr.com/811/40709845104_a49e33dd0b_z.jpg" alt="Image 4" className="slider-image" />
            <img src="https://live.staticflickr.com/854/27653388418_a99306d9d2_z.jpg" alt="Image 5" className="slider-image" />
            <img src="https://i.pinimg.com/736x/a7/d3/14/a7d314a721a705a70600ca280ad77dcb--pencil-education.jpg" alt="Image 6" className="slider-image" />
            <img src="https://img2.juzaphoto.com/002/shared_files/uploads/2565094_m.jpg" alt="Image 7" className="slider-image" />
            <img src="https://i.pinimg.com/736x/2c/fc/08/2cfc081aac56db188875c42c11f05821.jpg" alt="Image 8" className="slider-image" />
          </div>
          <button className="next-button" onClick={handleNext}>
            <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
          </button>

        </div>

        </>
  );
}
export default AmericanRobindetail;