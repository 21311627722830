import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ProductsContext } from "./BProductsContext";
import BirdsSlider from "./BirdsSlider/BirdsSlider";
import './birdstyle.css';

const Products = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const { products } = useContext(ProductsContext);

    const navigate = useNavigate();

    function detail(id) {
        if (id === 1) {
            navigate('/1goldfinch');
        }
        else if (id === 2) {
            navigate('/2AmericanRobin');
        }
        else if (id === 3) {
            navigate('/3ArcticTern');
        }
        else if (id === 4) {
            navigate('/4AnnaHummingbird');
        }
        else if (id === 5) {
            navigate('/5AtlanticPuffin');
        }
        else if (id === 6) {
            navigate('/6AustralianMagpie');
        }
        else if (id === 7) {
            navigate('/7AmericanKestrel');
        }
        else if (id === 8) {
            navigate('/8AmericanCrow');
        }
        else if (id === 9) {
            navigate('/9AlderFlycatcher');
        }

        else {
            navigate('/');
        }

    }

    return (
        <>
            <BirdsSlider />
            <div className='container'>

                <div className="galleryH">

                    <div className="gallery">
                        <img src={process.env.PUBLIC_URL + `/icon/icon1-gallery.png`} style={{ marginRight: '20px', marginLeft: '20px' }} />
                        <h1>Birds <span>Gallary</span></h1>
                    </div>

                    <div className="searchbar">
                        <input id="searchInput" type="text" placeholder="Search any Bird here..." onChange={(event) => {
                            setSearchTerm(event.target.value);
                        }} />
                    </div>

                </div>

                <div className="products">

                    {products

                        .filter((product) => {
                            if (searchTerm == "") {
                                return product;
                            } else if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return product;
                            }
                        })

                        .map((product) => {
                            return (
                                <div className="product" key={product.id}>

                                    <div className="product-image" >
                                        <img src={product.image} alt="not found" />
                                    </div>

                                    <audio controls >
                                        <source src={product.audio} />
                                    </audio>

                                    <div className="product-name">
                                        {product.name}
                                    </div>


                                    <button className="detail-button" onClick={() => detail(product.id)}>
                                        View Detail </button>
                                </div>
                            )

                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Products;





