import React, { useContext, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { ProductsContext } from "./AProductsContext";
import Slider from "./Slider/Slider";
import './animalstyle.css';


const Products = () => {
    const [searchTerm, setSearchTerm] = useState("");

    const { products } = useContext(ProductsContext);

    const navigate = useNavigate();

    function detail(id) {
        if (id === 1) {
            navigate('/Antelope-detail');
        }
        else if (id === 2) {
            navigate('/Alligator-detail');
        }
        else if (id === 3) {
            navigate('/Armadillo-detail');
        }
        else if (id === 4) {
            navigate('/Aardvark-detail');
        }
        else if (id === 5) {
            navigate('/Alpaca-detail');
        }
        else if (id === 6) {
            navigate('/Anaconda-detail');
        }
        else if (id === 7) {
            navigate('/Anteater-detail');
        }
        else if (id === 8) {
            navigate('/Elephant-detail');
        }
        else if (id === 9) {
            navigate('/Fox-detail');
        }
        else {
            navigate('/');
        }

    }

    return (
        <>
            <Slider />
            <div className='container'>

                <div className="galleryH">

                    <div className="gallery">
                        <img src={process.env.PUBLIC_URL + `/icon/icon1-gallery.png`} style={{ marginRight: '20px', marginLeft: '20px' }} />
                        <h1>Animal <span>Gallary</span></h1>
                    </div>

                    <div className="searchbar">
                        <input id="searchInput" type="text" placeholder="Search any Animal here..." onChange={(event) => {
                            setSearchTerm(event.target.value);
                        }} />
                    </div>

                </div>

                <div className="products">

                    {products

                        .filter((product) => {
                            if (searchTerm === "") {
                                return product;
                            } else if (product.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                return product;
                            }
                        })

                        .map((product) => {
                            return (
                                <div className="product" key={product.id}>

                                    <div className="product-image">
                                        <img src={product.image} alt="not found" />
                                    </div>

                                    <audio controls >
                                        <source src={product.audio} />
                                    </audio>

                                    <div className="product-name">
                                        {product.name}
                                    </div>


                                    <button className="detail-button" onClick={() => detail(product.id)}>
                                        View Detail </button>
                                </div>
                            )

                        })
                    }
                </div>
            </div>
        </>
    )
}

export default Products;





