import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function Alderdetail() {
  const sliderRef = useRef(null);


  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Bird</p>
      </div>


      {/* add video, add video, add video */}

      <video controls width="640px" height="360px">
        <source src='' type="video/mp4" />
      </video>


      {/* img img img img img img */}

      {/* <img className='fiximg' src={fiximage} alt='img' /> */}

      <div className='spic-box'>
        <div >

          <p><b>Scientific Name:</b> Empidonax alnorum</p><br />
          <p><b>Common Name    :</b> Alder Flycatcher </p><br />
          <p><b>life Span      :</b> Up to 6-7 years in the wild </p><br />
          <p><b>Diet           :</b> Insectivorous diet consisting of flies,<br /> bees,other insects</p><br />
          <p><b>Size           :</b> 12-14 cm (4.7-5.5 inches) in length </p><br />
          <p><b>Weight         :</b> 9-12 grams (0.3-0.4 ounces)</p><br />
          <p><b>Species        :</b> Empidonax alnorum is a member of the tyrant <br /> flycatcher family Tyrannidae</p><br />
          <p><b>Group   :</b> Birds </p>
        </div>

        {/* circle img circle img circle img */}

        <img className='spic-img' src='https://media.gettyimages.com/id/1319058441/photo/close-up-of-songflycatcher-perching-on-branch-restelo-lisboa-portugal.jpg?s=612x612&w=0&k=20&c=2UNvDvUgvvIsmYJF_g0RkPkqk07KK5a58u6KD6U9mgg=' alt='animal pic' />
      </div>

      <div className='detailboxx'>

        {/* animal name h2 */}

        <h2>Alder Flycatcher Bird</h2><br />
        <p>

          The Alder Flycatcher (Empidonax alnorum) and Willow Flycatcher (Empidonax traillii) are two closely<br /> related species of birds found in North America. While they share some similarities,<br /> they also have distinguishing features that set them apart. Let's explore these fascinating<br /> flycatchers and compare their range, calls, sounds, appearance, and other characteristics.<br />
          <h3>Range:</h3><br />
          The Alder Flycatcher primarily breeds in the northern United States and southern Canada, including<br /> regions such as New England, the Great Lakes, and parts of the Rocky Mountains.<br /> During the winter, it migrates to Central and South America. On the other hand, the Willow<br /> Flycatcher has a broader breeding range that extends across much of the United States, western<br /> Canada, and even into Mexico. Its winter range spans from Mexico to Central and South America.<br />
          <h3>Call and Sound:</h3><br />
          The Alder Flycatcher's call is a distinct "fee-bee-o," with the final note descending slightly. This<br /> call is repeated in a distinctive pattern. Its song is a rapid series of buzzy notes. In<br /> contrast, the Willow Flycatcher's call is a distinctive "fitz-bew" or "whit-whit" sound,<br /> with the final note rising in pitch. Its song consists of several introductory notes followed by a<br /> descending trill.<br />
          <h3>Appearance:</h3><br />

          Male Alder Flycatchers have olive-green upperparts, whitish underparts with a pale yellow wash, and<br /> a distinct white eye-ring. Their wings and tails are dark brown with pale edges. Willow<br /> Flycatchers have similar overall plumage but tend to have browner upperparts and a more prominent<br /> eye-ring.<br />
          <h3>Comparison:</h3><br />

          When comparing the two species side by side, several key differences become apparent. The Alder<br /> Flycatcher is generally smaller and has a shorter primary projection (the distance the primaries<br /> extend beyond the tertials) compared to the Willow Flycatcher. Additionally, the Alder Flycatcher<br /> has a less contrasting throat, with less yellowish coloration compared to the Willow<br /> Flycatcher.<br />
          <h3>Drawing and Images:</h3><br />

          To visualize the appearance of the Alder Flycatcher, one can refer to various field guides or online<br /> resources that provide illustrations or photographs of the species. These depictions<br /> capture the bird's unique features, such as its olive-green plumage, white eye-ring, and distinct<br /> wing patterns.<br />
          <h3>Voice and Song:</h3><br />

          The voice and song of the Alder Flycatcher are unique and can be recognized by experienced birders.<br /> Its call, with the descending "fee-bee-o" pattern, and the rapid, buzzy song are distinctive<br /> features. Recordings of the Alder Flycatcher's vocalizations can be found online or<br /> in birding guides, aiding in their identification.<br />
          <h3>New Jersey and Virginia:</h3><br />

          While the Alder Flycatcher is not as common in New Jersey compared to other states in its breeding<br /> range, it can be observed during migration. In Virginia, the Alder Flycatcher is a <br />rare visitor during the breeding season.<br />
          <h3>Occupancy and Conservation:</h3><br />

          The occupancy of Alder Flycatchers can vary across different habitats, and conservation efforts<br /> often focus on understanding their distribution and population trends. Occupancy modeling helps<br /> estimate their presence and evaluate the factors influencing their habitat preferences and <br />population dynamics.<br />
          <h3>Juvenile and Nesting:</h3><br />

          Juvenile Alder Flycatchers have a similar appearance to adults but may exhibit less contrast in <br />their plumage. Both species construct cup-shaped nests made of plant materials, such as grass, bark<br /> strips, and spider webs. The nests are typically situated in dense vegetation, close to<br /> water.<br />
          <h3>Scientific Name:</h3><br />

          The scientific name for the Alder Flycatcher is Empidonax alnorum. The genus name, Empidonax, refers<br /> to a group of small flycatchers known for their similar habits and appearance. The<br /> specific epithet, alnorum, alludes to the Alder Flycatcher's preference for alder thickets.<br />
          <h3>Habitat Preference:</h3><br />

          Alder Flycatchers favor wet habitats, such as alder thickets, swamps, and marshy areas near water<br /> bodies. They are often found in habitats with dense shrubbery and understory vegetation. Willow<br /> Flycatchers, on the other hand, inhabit a broader range of habitats, including riparian zones,<br /> wetlands, meadows, and shrubby areas.<br />
          <h3>Conclusion:</h3><br />
          In conclusion, the Alder Flycatcher and Willow Flycatcher are two closely related species with<br /> distinct characteristics that differentiate them. While they share similarities in their appearance<br /> and behavior, their range, calls, and other features help birders and researchers<br /> identify and study these intriguing birds in their natural habitats.<br />

        </p>
      </div>

      {/* slider code */}

      <div className="slider-container">
        <button className="prev-button" onClick={handlePrev}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
        </button>
        <div className="slider" ref={sliderRef}>
          <img src="https://www.allaboutbirds.org/guide/assets/og/75222691-1200px.jpg" alt="Image 1" className="slider-image" />
          <img src="https://live.staticflickr.com/1424/717939053_9129f3a3cd.jpg" alt="Image 2" className="slider-image" />
          <img src="https://a4.pbase.com/o9/29/866029/1/163230970.na94jGqx.ALFL1a.jpg" alt="Image 3" className="slider-image" />
          <img src="https://stpaulislandtour.com/wp-content/uploads/2019/02/Alder-Flycatcher-2-by-Doug-Gochfeld-1024x632.jpg" alt="Image 4" className="slider-image" />
          <img src="https://www.greatbirdpics.com/wp-content/uploads/2019/10/Alder-Flycatcher.jpg" alt="Image 5" className="slider-image" />
          <img src="https://2.bp.blogspot.com/-O8oZ31UxanA/WTnKUIgtP8I/AAAAAAAABZ0/-WU9dpfwtxk3oG2O-xAAQVGzu4_wEINpACEw/s1600/Alder%2BFlycatcher.JPG" alt="Image 6" className="slider-image" />
          <img src="https://live.staticflickr.com/7198/6966133159_09f38e961d.jpg" alt="Image 7" className="slider-image" />
          <img src="https://media.arkansasonline.com/img/photos/2016/09/25/resized_250499-flycatchers001_80-21881_t800.JPG?90232451fbcadccc64a17de7521d859a8f88077d" alt="Image 8" className="slider-image" />
        </div>
        <button className="next-button" onClick={handleNext}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
        </button>

      </div>
    </>
  );
}
export default Alderdetail;