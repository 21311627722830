import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fixantelop from './fix-photos/antelope.png';
import { Link } from 'react-router-dom';

function Antelopedetail() {



  const sliderRef = useRef(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };
  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Animal</p>
      </div>

      <video controls>
        <source src='https://player.vimeo.com/external/345878972.sd.mp4?s=21229ec26506485653408d1030de3b5a5ae958a6&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
      </video>

      <img className='fiximg' src={fixantelop} alt='img' />

      <div className='spic-box'>
        <div>
          <p><b>Scientific Name:</b> Bovidae</p><br />
          <p><b>Common Name:</b> Antelope</p><br />
          <p><b>life Span:</b> 20 years</p><br />
          <p><b>Diet:</b> Herbivore</p><br />
          <p><b>Size:</b> 1.8 meters (6 feet)</p><br />
          <p><b>Weight:</b> 900 kg (1,980 lbs)</p><br />
          <p><b>Species:</b> 90</p><br />
          <p><b>Animal Group:</b> Herbivore</p>
        </div>
        <img className='spic-img' src='https://images.unsplash.com/photo-1566034356854-23137d8128de?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW50ZWxvcGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60' alt='animal pic' style={{ width: "200px" }} />
      </div>
      <a href="https://chat.openai.com" target="_blank" rel="noopener noreferrer">Go to Example.com</a>


      <div className='detailboxx'>
        <h2>Antelope</h2><br />
        <p>Antelopes are a group of herbivorous mammals belonging to the Bovidae family, which also<br /> includes goats, sheep, and cattle. They are found in various habitats across Africa, Asia, and<br /> North America and are known for their speed, agipty, and graceful movements. In this section,<br /> we will discuss some of the key features of antelopes.<br /><br />

          <h3>Physical Characteristics:</h3><br />
          Antelopes vary in size, from the tiny Royal antelope that weighs only 3-4 kg to the giant <br />Eland antelope, which can weigh up to 900 kg. They have slender bodies, long legs, and a <br />distinctive pair of horns that grow from their heads. These horns are often used for defense<br /> and can also be a symbol of social status among male antelopes.<br /><br />

          <h3>Habitat and Diet:</h3><br />
          Antelopes are found in a variety of habitats, ranging from grasslands and savannas to deserts<br /> and forests. They are herbivores and feed on a variety of plant materials, <br />including grasses, leaves, and fruits.<br /><br />

          <h3>Behavior and Social Structure:</h3><br />
          Antelopes are social animals that pve in herds ranging from a few individuals to several<br /> hundred. They have a hierarchical social structure, with dominant males controlpng access to<br /> resources such as food, water, and mates. During mating season, males often engage in<br /> displays of strength and aggression to estabpsh their dominance and attract females.<br /><br />

          <h3>Predators and Threats:</h3><br />
          Antelopes are preyed upon by a range of predators, including pons, leopards, cheetahs,<br /> hyenas, and wild dogs. They also face threats from habitat loss, poaching, and hunting for<br /> their meat, horns, and hides.<br /><br />

          <h3>Conservation Status:</h3><br />
          Many species of antelopes are psted as threatened or endangered due to habitat loss and<br /> poaching. Several conservation efforts are in place to protect these animals, including the<br /> estabpshment of national parks and wildpfe reserves, the enforcement of anti-poaching <br />laws, and the promotion of sustainable hunting practices.<br /><br />

          In conclusion, antelopes are a diverse and fascinating group of mammals that play a vital role<br /> in their ecosystems. They are known for their unique physical characteristics,<br /> social behavior, and impressive speed and agipty. However, many species of antelopes are <br />facing threats to their survival, and it is important to take steps to protect and conserve <br />these animals for future generations.<br /><br />

          <h3>Species</h3><br />
          There are over 90 species of antelopes found worldwide, which are distributed across the<br /> continents of Africa, Asia, and North America. Some of the most common antelope species<br /> include the Impala, Springbok, Gazelle, Wildebeest, Kudu, and Oryx. Each species of antelope<br /> has its unique physical characteristics, behavior, and habitat preferences.<br /> Despite their similarities, antelope species exhibit considerable variation in terms of size,<br /> color, and horn structure.<br /><br />
        </p><br /><br />
        
        <script
                type="text/javascript"
                dangerouslySetInnerHTML={{
                  __html: `
                    atOptions = {
                      'key': 'bb5a6fb407b526bc46a1ef4abfaca5a8',
                      'format': 'iframe',
                      'height': 250,
                      'width': 300,
                      'params': {}
                    };
                    document.write('<scr' + 'ipt type="text/javascript" src="http' + (location.protocol === 'https:' ? 's' : '') + '://www.profitabledisplaynetwork.com/bb5a6fb407b526bc46a1ef4abfaca5a8/invoke.js"></scr' + 'ipt>');
                  `
                }}></script>

        <h3>Related Animals:-</h3>
        <div style={{ display: 'flex' }} className='hlist'>
          <Link to="/Alligator-detail">1<img src="https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Armadillo-detail">2<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Aardvark-detail">3<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Alpaca-detail">4<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Anaconda-detail">5<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Anteater-detail">6<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Elephant-detail">7<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/Fox-detail">8<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
          <Link to="/">10<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
        </div>


        <div className="slider-container">
          <hr />
          <button className="prev-button" onClick={handlePrev}>
            <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
          </button>
          <div className="slider" ref={sliderRef}>
            <img src="https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 1" className="slider-image" />
            <img src="https://images.unsplash.com/photo-1566034356854-23137d8128de?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8YW50ZWxvcGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60" alt="Image 2" className="slider-image" />
            <img src="https://images.pexels.com/photos/4577521/pexels-photo-4577521.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 3" className="slider-image" />
            <img src="https://images.unsplash.com/photo-1567608198472-6796ad9466a2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTV8fGFudGVsb3BlfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="Image 4" className="slider-image" />
            <img src="https://images.unsplash.com/photo-1647710804955-8696a3743ed9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTN8fGFudGVsb3BlJTIwYmFieXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="Image 5" className="slider-image" />
            <img src="https://images.unsplash.com/photo-1648408969586-46d4f264f97a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGFudGVsb3BlJTIwYmFieXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="Image 6" className="slider-image" />
            <img src="https://cdn.pixabay.com/photo/2019/12/09/11/43/animal-4683342__340.jpg" alt="Image 7" className="slider-image" />
            <img src="https://images.pexels.com/photos/1109896/pexels-photo-1109896.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 8" className="slider-image" />
          </div>
          <button className="next-button" onClick={handleNext}>
            <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
          </button>

        </div>
      </div>







    </>
  );
}
export default Antelopedetail;