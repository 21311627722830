import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function AtlanticPuffindetail() {
  const sliderRef = useRef(null);


  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Bird</p>
      </div>


      {/* add video, add video, add video */}

      <video controls width="640px" height="360px">
        <source src='https://player.vimeo.com/progressive_redirect/playback/823476801/rendition/360p/file.mp4?loc=external&oauth2_token_id=57447761&signature=dbfbcba1448d8dfc1902392cbc50ab7d65efde5d89904698e271c27a9df54bcc' type="video/mp4" />
      </video>


      {/* img img img img img img */}

      {/* <img className='fiximg' src={fiximage} alt='img' /> */}

      <div className='spic-box'>
        <div >

          <p><b>Scientific Name:</b> Fratercula arctica</p><br />
          <p><b>Common Name    :</b> Atlantic Puffin</p><br />
          <p><b>life Span      :</b> Up to 20 years in the wild</p><br />
          <p><b>Diet           :</b> Carnivorous diet consisting of<br /> small fish, crustaceans, and squid</p><br />
          <p><b>Size           :</b>  28-30 cm (11-12 inches) in length</p><br />
          <p><b>Weight         :</b> 300-500 grams (10.5-17.5 ounces)</p><br />
          <p><b>Species        :</b> Fratercula arctica is a member of the auk family Alcidae</p><br />
          <p><b>Group   :</b> Birds</p>
        </div>

        {/* circle img circle img circle img */}

        <img className='spic-img' src='https://images.unsplash.com/photo-1490718687940-0ecadf414600?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8cHVmZmlufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60' alt='animal pic' />
      </div>

      <div className='detailboxx'>

        {/* animal name h2 */}

        <h2>Atlantic Puffin</h2><br />
        <p>
          A Fascinating Seabird of the North Atlantic<br />
          <h3>Scientifically Name:</h3><br />
          The Atlantic Puffin, scientifically known as Fratercula arctica, is a charismatic and iconic seabird<br /> that inhabits the North Atlantic Ocean. With its distinctive appearance and captivating<br /> behavior, the Atlantic Puffin has captured the hearts of bird enthusiasts and <br />nature lovers around the world. In this article, we will explore various aspects of this remarkable<br /> bird, including its habitat, range, physical characteristics, behavior, and<br /> conservation status.<br />
          <h3>Species:</h3><br />
          The Atlantic Puffin is a member of the auk family, which also includes other puffin species such as<br /> the Horned Puffin and the Tufted Puffin. Puffins are often referred to as "sea parrots" due<br /> to their colorful beaks and clown-like facial expressions. They are small to medium-sized <br />birds, typically measuring about 25 centimeters in length and weighing around 400 grams.<br />
          <h3>Features of atlantic puffin:</h3><br />
          One of the most striking features of the Atlantic Puffin is its vibrant beak, which displays bright<br /> orange, yellow, and blue colors during the breeding season. This distinct beak plays a<br /> vital role in courtship displays and is also used to carry multiple fish at a time, making it<br /> easier for the puffins to transport food to their chicks.<br />
          <h3>About Atlantic Puffin:</h3><br />
          The Atlantic Puffin is primarily a pelagic bird, spending most of its life out at sea. However,<br /> during the breeding season, these birds gather in large colonies on coastal cliffs and islands, <br />where they excavate burrows in the soil or find suitable rock crevices for nesting. These nesting<br /> sites are crucial for their survival and provide protection from predators, such as<br /> gulls and eagles.<br />
          <h3>Habitat:</h3><br />
          Speaking of habitat, the Atlantic Puffin is found along the coasts of the North Atlantic, from the<br /> northeastern United States and eastern Canada to the coasts of northern Europe, including<br /> Iceland, the Faroe Islands, Norway, and the British Isles. These regions offer the<br /> puffins an abundant food supply, consisting mainly of small fish like herring, sand eels, and<br /> capelin. Puffins are excellent divers and can plunge underwater to depths of up to 60 meters in<br /> search of their prey.<br />
          <h3>Breeding:</h3><br />
          The breeding season is a significant time for the Atlantic Puffin, as they form monogamous pairs and<br /> engage in elaborate courtship rituals. During this time, puffins display an array of<br /> behaviors, including bill fencing, head nodding, and mutual preening. Once a pair is formed, they<br /> will lay a single egg in their burrow, which is then incubated by both parents for about six<br /> weeks. After hatching, the parents take turns in feeding the chick with regurgitated fish until it<br /> is ready to fledge.<br />
          <h3>Atlantic Puffin faces numerous threats:</h3><br />
          Unfortunately, like many other seabird species, the Atlantic Puffin faces numerous threats to its<br /> survival. Habitat degradation, caused by factors such as climate change, pollution, and<br /> overfishing, can impact the availability of food for the puffins. Additionally, introduced<br /> predators, like rats and mink, pose a threat to nesting colonies, as they prey on the eggs and<br /> chicks. Conservation efforts are crucial to protect these remarkable birds and their breeding sites.<br />
          <h3>Government work for atlantic puffin:</h3><br />
          Several organizations and governments are working towards the conservation of the Atlantic Puffin<br /> and its habitat. Measures include the establishment of protected areas, monitoring of breeding<br /> colonies, and research to better understand their ecology and behavior. Education and awareness<br /> programs are also vital in engaging the public and promoting responsible stewardship of our oceans<br /> and coastal environments.<br />
          <h3>Conclusion:</h3><br />
          In conclusion, the Atlantic Puffin is a fascinating seabird that has captivated people's imagination<br /> with its unique appearance and behavior.<br />

        </p>
      </div>

      {/* slider code */}

      <div className="slider-container">
        <button className="prev-button" onClick={handlePrev}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
        </button>
        <div className="slider" ref={sliderRef}>
          <img src="https://1.bp.blogspot.com/-0Jwf5uWs8RI/UI26v3WeaSI/AAAAAAAAGQM/TWs0lrDPwes/s1600/Atlantic-Puffin-15.jpg" alt="Image 1" className="slider-image" />
          <img src="https://4.bp.blogspot.com/-sA-MwPIr-lk/URqMcI0-znI/AAAAAAAAE1k/7HEqzr7TS2M/s1600/Atlantic-Puffin-3.jpg" alt="Image 2" className="slider-image" />
          <img src="https://multifiles.pressherald.com/uploads/sites/4/2015/04/080406-puffin-6.jpg" alt="Image 3" className="slider-image" />
          <img src="https://www.motherjones.com/wp-content/uploads/puffins.jpg?w=990" alt="Image 4" className="slider-image" />
          <img src="https://seancrane.com/blog/wp-content/uploads/2018/05/Puffin_10.jpg" alt="Image 5" className="slider-image" />
          <img src="https://www.birdsasart-blog.com/baa/wp-content/gallery/general-2017/Atlantic-Puffin-w-bill-open-_Y5O4423-Islands-off-Seahouses-UK.jpg" alt="Image 6" className="slider-image" />
          <img src="https://owendeutsch.com/wp-content/uploads/2020/07/Atlantic-Puffin-242RSC.jpg" alt="Image 7" className="slider-image" />
          <img src="https://besthqwallpapers.com/Uploads/18-7-2018/59822/thumb2-puffins-flowers-wildlife-fratercula-arctica-cute-birds.jpg" alt="Image 8" className="slider-image" />
        </div>
        <button className="next-button" onClick={handleNext}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
        </button>

      </div>

    </>
  );
}
export default AtlanticPuffindetail;