import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/Fox.png';
import { Link } from 'react-router-dom';


function Foxdetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src='https://player.vimeo.com/external/370050277.sd.mp4?s=b7a1829f0a23a3d6cb060dcf008ad5fba21cdd59&profile_id=164' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b><br />Red Fox: Vulpes vulpes<br />
                        Arctic Fox: Vulpes lagopus<br />
                        Gray Fox: Urocyon cinereoargenteus<br />
                        Fennec Fox: Vulpes zerda<br />
                        Swift Fox: Vulpes velox<br />
                        Kit Fox: Vulpes macrotis</p><br />
                    <p><b>Common Name    :</b> Fox</p><br />
                    <p><b>life Span      :</b> 3 to 4 years</p><br />
                    <p><b>Diet           :</b>  omnivores,<br />they eat both plant and animal matter.</p><br />
                    <p><b>Size           :</b> length ranging from 45 to 90 cm (18 to 35 inches)</p><br />
                    <p><b>Weight         :</b> weight ranging from 0.5 to 14 kg (1.1 to 31 lb)</p><br />
                    <p><b>Species        :</b> <br />1.Red fox (Vulpes vulpes)<br />
                        2.Arctic fox (Vulpes lagopus)<br />
                        3.Gray fox (Urocyon cinereoargenteus)<br />
                        4.Fennec fox (Vulpes zerda)<br />
                        5.Swift fox (Vulpes velox)<br />
                        6.Bengal fox (Vulpes bengalensis)<br />
                        7.Island fox (Urocyon littoralis)</p><br />
                    <p><b>Animal Group   :</b>members of the Canidae family</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://wallpapercave.com/wp/Pv4vAHn.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Fox</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}


                    Foxes are fascinating animals that are known for their striking appearance and unique behavior. They<br /> are members of the Canidae family, which also includes dogs, wolves, coyotes, and jackals.<br /> Within this family, foxes belong to the genus Vulpes and are characterized by their long, <br />bushy tails and pointed ears. In this article, we will explore the different species of foxes, <br />their habitats, diets, behaviors, and interesting facts.<br />

                    <h3> species of fox</h3><br />

                    There are several different species of foxes, each with its own unique characteristics. Some of the <br />most well-known species include:<br />

                    Red Fox (Vulpes vulpes) - This is the most common type of fox and is found in many parts of the<br /> world, including North America, Europe, and Asia. The red fox has a reddish-brown coat and a long, <br />bushy tail.<br />

                    Arctic Fox (Vulpes lagopus) - This species is adapted to life in cold climates and is found in the<br /> Arctic regions of North America, Europe, and Asia. The Arctic fox has a thick, white coat<br /> that helps it to blend in with its snowy surroundings.<br />

                    Fennec Fox (Vulpes zerda) - This is the smallest species of fox and is found in the Sahara desert of<br /> North Africa. The fennec fox has large ears that help it to dissipate heat and locate prey<br /> in the desert.<br />

                    <h3> Habitat</h3><br />

                    Foxes can be found in a variety of habitats, including forests, grasslands, deserts, and Arctic<br /> tundras. Different species of foxes have adapted to survive in their specific habitats, and their<br /> physical characteristics reflect these adaptations. For example, the Arctic fox has a thick <br />coat<br /> that helps it to stay warm in cold climates, while the fennec fox has large ears that <br />help it to dissipate heat in the hot desert.<br />

                    <h3> Diet</h3><br />

                    Foxes are omnivorous, which means they eat both meat and plants. Their diet includes small mammals<br /> such as rodents, rabbits, and birds, as well as fruits, berries, and insects. The <br />specific diet of a fox depends on its habitat and the availability of food.<br />

                    <h3> Behaviour</h3><br />

                    Foxes are solitary animals, except during mating season, when they form pairs. They are also<br /> nocturnal, which means they are most active at night. Foxes are known for their intelligence and<br /> adaptability, and they have been observed using tools and exhibiting problem-solving skills.<br />

                    <h3> Interesting Facts</h3><br />

                    Foxes are capable of running up to 45 miles per hour, making them one of the fastest animals in the<br /> world.<br />

                    Foxes have excellent hearing and can locate prey using sound alone.<br />

                    Foxes are known for their ability to adapt to urban environments and can often be found living in<br /> cities and towns.<br />

                    <h3> Is Fox a Cat or Dog?</h3><br />

                    Foxes are not cats or dogs, although they are members of the Canidae family, which includes dogs,<br /> wolves, coyotes, and jackals. While foxes share some physical characteristics with cats, such as<br /> their sharp claws and pointed ears, they are more closely related to dogs.<br />

                    <h3> Why are Foxes Special?</h3><br />

                    Foxes are special because they are highly adaptable animals that have been able to thrive in a<br /> variety of habitats. They are intelligent, agile, and have a unique beauty that makes them a beloved<br /> animal around the world. Foxes are also important to their ecosystems, as they help to<br /> control populations of small mammals and insects. Overall, foxes are fascinating animals <br />that continue to capture the attention and curiosity of people around the world.<br />

                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Aardvark-detail">1<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">2<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">3<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">4<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">5<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">6<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">7<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">8<img src="https://cdn.pixabay.com/photo/2021/09/26/11/56/animal-6657488_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/11/13/15/25/barbary-ape-3813278_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://images.pexels.com/photos/12800361/pexels-photo-12800361.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>

                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://wallup.net/wp-content/uploads/2016/01/277413-fox-animals.jpg" alt="Image 1" className="slider-image" />
                    <img src="https://www.thesprucepets.com/thmb/Gglua07RlpXEp9C2mjqN2oJaJvI=/2121x1416/filters:fill(auto,1)/153815436-56a2bcfb5f9b58b7d0cdf8ff.jpg" alt="Image 2" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2017/01/14/12/59/iceland-1979445__340.jpg" alt="Image 3" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2016/11/22/19/26/fox-1850186__340.jpg" alt="Image 4" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2015/10/12/22/43/fox-985292__340.jpg" alt="Image 5" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2016/10/10/22/25/coyote-1730060__340.jpg" alt="Image 6" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2014/02/27/16/09/fox-275961__340.jpg" alt="Image 7" className="slider-image" />
                    <img src="https://i.redd.it/9vy19m3z2g421.png" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Foxdetail;