import React, { createContext, useState } from "react";
import Products from "./AAProducts";
import {Helmet } from 'react-helmet-async';

export const ProductsContext = createContext();

const SeeAnimalsContextProvider = (props) => {
    const [products] = useState([
        {
            id: 1,
            name: 'Angelfish',
            image: "https://cdn.pixabay.com/photo/2013/11/25/17/25/angelfish-218086_640.jpg",

        },
        {
            id: 2,
            name: 'Archerfish',
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT8ZY8OBWm52gjdeOYNfvbj3K0-8P3uQWBsQXFmp_0r3GanRtQ725jxzZfW7HEdYSl0jp8&usqp=CAU",

        },
        {
            id: 3,
            name: 'Axolotl',
            image: "https://images.pexels.com/photos/2168831/pexels-photo-2168831.jpeg?auto=compress&cs=tinysrgb&w=600",

        },
        {
            id: 4,
            name: 'Arowana',
            image: "https://images.pexels.com/photos/8985043/pexels-photo-8985043.jpeg?auto=compress&cs=tinysrgb&w=600",

        },
        {
            id: 5,
            name: 'Atlantic salmon',
            image: "https://salmonfacts.org/wp-content/uploads/2022/06/Wild-Atlantic-Salmon.jpg",

        },
        {
            id: 6,
            name: 'Amazon river dolphin',
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgaGuJjH1rKCo-TPM5vkr3vAtc3rb9DOoZDvW8goyyZHuSSr5ha6r6u-woaRGIh8qcm2U&usqp=CAU",

        },
        {
            id: 7,
            name: 'Australian lungfish',
            image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSk7X2yxoSjjliellj8onM-L9n6vQaebnQYGOPsdxssZE_H75KAABVyndbPP40LI0PEpgU&usqp=CAU",

        },
        {
            id: 8,
            name: 'Barracuda',
            image: "https://images.pexels.com/photos/66218/pexels-photo-66218.jpeg?auto=compress&cs=tinysrgb&w=600",

        },
        {
            id: 9,
            name: 'Beluga whale',
            image: "https://cdn.pixabay.com/photo/2021/02/12/15/33/beluga-whale-6008844_640.jpg",

        },

    ]);

    return (
        <>
        <Helmet>
        <title>Aquatic Animals</title>
        <meta name='description' content='THE NATURAL WORLD Aquatic Animals page'></meta>
        <link rel='canonical' href='/AAProductsContext' />
    </Helmet>
        <ProductsContext.Provider value={{ products: [...products] }}>
            {props.children}
            <Products />
        </ProductsContext.Provider>
        </>
    )
}

export default SeeAnimalsContextProvider;


