import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function ArcticTerndetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
      const sliderInterval = setInterval(() => {
        window.scrollTo(0, 0);
        if (sliderRef.current) {
          const slider = sliderRef.current;
          const sliderWidth = slider.offsetWidth;
          const currentScroll = slider.scrollLeft;
          const maxScroll = slider.scrollWidth - sliderWidth;
  
          if (currentScroll >= maxScroll) {
            // When reaching the last image, scroll back to the first image
            slider.scrollTo({
              left: 0,
              behavior: 'smooth',
            });
          } else {
            // Scroll to the next image
            slider.scrollBy({
              left: sliderWidth,
              behavior: 'smooth',
            });
          }
        }
      }, 5000);
  
      return () => {
        clearInterval(sliderInterval);
      };
    }, []);
  
    const handlePrev = () => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
  
        if (currentScroll === 0) {
          // When on the first image, scroll to the last image
          slider.scrollTo({
            left: slider.scrollWidth,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the previous image
          slider.scrollBy({
            left: -sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    };
  
    const handleNext = () => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - slider.offsetWidth;
  
        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    };



    
   return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Bird</p>
            </div>


            {/* add video, add video, add video */}

            <video controls width="640px" height="360px">
                <source src='https://player.vimeo.com/external/562429684.sd.mp4?s=ba186ee1fc421bcf813b42c95e5bb7eb932f395b&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            {/* <img className='fiximg' src={fiximage} alt='img' /> */}

            <div  className='spic-box'>
                <div>

                    <p><b>Scientific Name:</b> Sterna paradisaeav</p><br />
                    <p><b>Common Name    :</b> Arctic Tern</p><br />
                    <p><b>life Span      :</b> 20-30 years in the wild</p><br />
                    <p><b>Diet           :</b> Carnivorous diet consisting of fish,<br/> crustaceans, and insects</p><br />
                    <p><b>Size           :</b> 28-39 cm (11-15 inches) in length</p><br />
                    <p><b>Weight         :</b> 85-125 grams (3-4.4 ounces)</p><br />
                    <p><b>Species        :</b> Sterna paradisaea is a member of the gull family Laridae</p><br />
                    <p><b>Group   :</b> Birds</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://cdn.pixabay.com/photo/2018/12/14/17/52/bird-3875483__340.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2></h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}

                </p>
            </div>

            {/* slider code */}

            <div className="slider-container">
          <button className="prev-button" onClick={handlePrev}>
            <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
          </button>
          <div className="slider" ref={sliderRef}>
            <img src="" alt="Image 1" className="slider-image" />
            <img src="" alt="Image 2" className="slider-image" />
            <img src="" alt="Image 3" className="slider-image" />
            <img src="" alt="Image 4" className="slider-image" />
            <img src="" alt="Image 5" className="slider-image" />
            <img src="" alt="Image 6" className="slider-image" />
            <img src="" alt="Image 7" className="slider-image" />
            <img src="" alt="Image 8" className="slider-image" />
          </div>
          <button className="next-button" onClick={handleNext}>
            <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
          </button>

        </div>

        </>
  );
}
export default ArcticTerndetail;