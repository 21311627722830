import React from 'react';
import { Helmet } from 'react-helmet-async';
function About() {
    return (
        <>
            <Helmet>
                <title>ABOUT</title>
                <meta name='description' content='THE NATURAL WORLD about page'></meta>
                <link rel='canonical' href='/about' />

            </Helmet>
            
            <div className="abtdiv">
                <div className='abtlogo'>
                    <img src={process.env.PUBLIC_URL + `/logo1.jpg`} alt="img" height={'450px'} width={'450px'}/>
                </div>
                <p>
                    <h1>Some information About Us:</h1> <br/>
                    Welcome to our website about animals and birds! Our site is dedicated<br />
                    to bringing you the latest and most interesting news and information<br />
                    about the amazing creatures that inhabit our planet.<br /><br />

                    At our website, we are passionate about wildlife and the natural world.<br />
                    Our team of writers and researchers are all experts in their fields,<br />
                    and they are dedicated to bringing you the most accurate and up-to-date <br />
                    information about animals and birds from all around the world.<br /><br />

                    Our goal is to provide a one-stop resource for all things related to<br />
                    animals and birds. Whether you are interested in learning more about<br />
                    your favorite animal or discovering new and fascinating species, our site<br />
                    has something for everyone.<br /><br />

                    Our content is carefully curated and written to be both informative<br />
                    and engaging. We believe that learning about animals and birds should<br />
                    be fun and accessible to everyone, regardless of their age or background.<br />
                    That's why we strive to make our content easy to understand and enjoyable<br />
                    to read.<br /><br />

                    We also believe in the importance of conservation and protecting our<br />
                    natural world. Many species of animals and birds are facing threats<br />
                    to their habitats and populations, and it is up to all of us to help<br />
                    protect them. That's why we regularly feature articles and resources<br />
                    about conservation efforts and ways that you can get involved.<br /><br />

                    Thank you for visiting our website, and we hope that you enjoy<br />
                    exploring all that we have to offer. </p>
            </div>
            
        </>
    );
}
export default About;