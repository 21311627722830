import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function AmericanCrowdetail() {
  const sliderRef = useRef(null);


  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Bird</p>
      </div>


      {/* add video, add video, add video */}

      <video controls width="640px" height="360px">
        <source src='https://player.vimeo.com/external/279187902.sd.mp4?s=9e22a79858dc665e7e16aeea9044cfda85b1485f&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
      </video>


      {/* img img img img img img */}

      {/* <img className='fiximg' src={fiximage} alt='img' /> */}

      <div className='spic-box'>
        <div >

          <p><b>Scientific Name:</b> Corvus brachyrhynchos</p><br />
          <p><b>Common Name    :</b>  American Crow</p><br />
          <p><b>life Span      :</b> Up to 7-8 years in the wild</p><br />
          <p><b>Diet           :</b> Omnivorous diet consisting of insects, <br /> fruits, seeds, and carrion</p><br />
          <p><b>Size           :</b> 40-50 cm (16-20 inches) in length</p><br />
          <p><b>Weight         :</b> 300-600 grams (10.6-21.2 ounces)</p><br />
          <p><b>Species        :</b> Corvus brachyrhynchos is a member of the crow family Corvidae</p><br />
          <p><b>Group   :</b> Birds</p>
        </div>

        {/* circle img circle img circle img */}

        <img className='spic-img' src='https://images.unsplash.com/photo-1559649191-b9d89ad31819?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Y3Jvd3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=400&q=60' alt='animal pic' />
      </div>

      <div className='detailboxx'>

        {/* animal name h2 */}

        <h2>American Kestrel</h2><br />
        <p>
          A Small but Mighty Falcon<br />

          The American Kestrel (Falco sparverius) is a small but vibrant bird of prey that is native to North<br /> and South America. Known for its stunning plumage and impressive hunting abilities, the<br /> American Kestrel has captured the attention of bird enthusiasts and nature lovers alike. In<br /> this article, we will explore various aspects of this remarkable falcon, including its size,<br /> nesting behavior, habitat, interesting facts, and its cultural significance.<br />
          <h3>About American Kestrel:</h3><br />
          The American Kestrel is the smallest falcon in North America, measuring about 8-12 inches in length<br /> with a wingspan of 20-24 inches. The males and females of this species exhibit sexual<br /> dimorphism, with the males sporting a striking combination of blue-gray wings, a rust-colored<br /> back, and a white belly with black spots. The females, on the other hand, have a brownish plumage<br /> with dark streaks.<br />
          <h3>Nest boxes:</h3><br />
          To encourage the nesting and conservation of American Kestrels, nest boxes have been widely used.<br /> These nest boxes mimic natural tree cavities and provide suitable nesting sites for the kestrels.<br /> They are usually placed on poles or trees in open areas such as fields, meadows, or<br /> agricultural lands. By providing artificial nest boxes, conservationists aim to enhance the breeding<br /> success of these falcons and promote their population growth.<br />
          <h3>Location:</h3><br />
          While the American Kestrel is found throughout North and South America, there is a specific<br /> subspecies called the Southeastern American Kestrel (Falco sparverius paulus) that is endemic to the<br /> southeastern United States. It is smaller than its northern counterparts and has a more<br /> muted coloration. In states like Florida, the American Kestrel is a common sight, particularly in<br /> open habitats such as grasslands, agricultural areas, and roadside perches.<br />
          <h3>About Nests:</h3><br />
          American Kestrels build their nests in tree cavities, abandoned woodpecker holes, or even nesting<br /> boxes provided by conservationists. The female kestrel lays a clutch of 4-7 eggs, which she<br /> incubates for about 29-31 days. Both parents share the responsibilities of incubation and caring for<br /> the hatchlings. The young kestrels fledge after about 28-31 days and become independent<br /> within a few weeks.<br />
          <h3>American kestrel 1 cent stamp 1999:</h3><br />
          In 1999, the United States Postal Service issued a 1-cent stamp featuring the American Kestrel. This<br /> stamp showcases the stunning beauty of this falcon and raises awareness about its<br /> conservation needs. The stamp design captures the intricate details of the kestrel's plumage and<br /> highlights its importance as an emblematic bird of prey.<br />
          <h3>Interesting facts:</h3><br />
          Now, let's explore some interesting facts about the American Kestrel:<br />

          1.The American Kestrel is not only a skilled hunter but also a versatile flier. It can hover in<br /> mid-air, rapidly adjust its flight direction, and perform aerial acrobatics to catch its prey, which<br /> mainly consists of insects, small mammals, birds, and reptiles.<br />

          2.Despite its small size, the American Kestrel is known for its fierce hunting abilities. It can spot<br /> its prey from a distance, thanks to its excellent vision, and swiftly dive down to capture<br /> it with its sharp talons.<br />

          3.American Kestrels are highly adaptable birds and can thrive in various habitats, including<br /> grasslands, deserts, farmlands, and urban areas. This adaptability has contributed to their wide<br /> distribution across the Americas.<br />

          4.Unlike many other birds of prey, the American Kestrel does not build its nests in high treetops or<br /> cliffs. Instead, it prefers to nest in cavities closer to the ground, making it easier to<br /> access its hunting grounds.<br />

          5.The American Kestrel has a distinctive call, often described as a high-pitched, repetitive "klee,<br /> klee,<br />
          This vocalization is used for communication between mates, defending territory, and<br /> attracting potential mates during courtship displays.<br />
          <h3>Plays important role in ecological balance:</h3><br />
          The American Kestrel plays an essential role in maintaining ecological balance as a predator. By<br /> controlling populations of small mammals and insects, it helps regulate the food web and<br /> contributes to the overall health of the ecosystem.<br />
          In terms of cultural significance, the American Kestrel holds a special place in the hearts of bird<br /> enthusiasts and falconry enthusiasts. Its beauty, agility, and hunting prowess have made<br /> it a popular subject for bird artwork, paintings, and photographs. Additionally, the<br /> American Kestrel serves as an ambassador for bird conservation, highlighting the importance of<br /> protecting and preserving the natural habitats that these remarkable birds rely on.<br />
          <h3>Conclusion:</h3><br />
          In conclusion, the American Kestrel is a small but mighty falcon that captivates us with its beauty,<br /> agility, and hunting skills. From its vibrant plumage to its acrobatic flight, the kestrel is<br /> a true marvel of nature. By understanding and appreciating these magnificent birds, we can<br /> work towards their conservation and ensure their continued presence in the diverse<br /> ecosystems they call home.<br />

        </p>
      </div>

      {/* slider code */}

      <div className="slider-container">
        <button className="prev-button" onClick={handlePrev}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
        </button>
        <div className="slider" ref={sliderRef}>
          <img src="https://i.redd.it/0501yoyqkan21.jpg" alt="Image 1" className="slider-image" />
          <img src="https://cdn2.outdoorphotographer.com/galleries/9328/member-galleries-jackdean3-0-1488929002-1200x960.jpg" alt="Image 2" className="slider-image" />
          <img src="https://1.bp.blogspot.com/-3PbL-5qAyD8/XA6XjX3FzmI/AAAAAAAA5Gc/t-7PNVCmgoAp495Iq1eCKcghDe3NgM6NwCEwYBhgL/s1600/kestrel-sky-4852.jpg" alt="Image 3" className="slider-image" />
          <img src="https://1.bp.blogspot.com/-lxjs6UyjieI/TyAOGnSp_eI/AAAAAAAABgE/S0_4d0c0ahU/s1600/kestrel.jpg" alt="Image 4" className="slider-image" />
          <img src="https://cdn.download.ams.birds.cornell.edu/api/v1/asset/70583921/1800" alt="Image 5" className="slider-image" />
          <img src="https://www.animalspot.net/wp-content/uploads/2015/08/American-Kestrel-Female.jpg" alt="Image 6" className="slider-image" />
          <img src="https://www.featheredphotography.com/blog/wp-content/uploads/2017/11/american-kestrel-9821-ron-dudley.jpg" alt="Image 7" className="slider-image" />
          <img src="https://intobirds.com/wp-content/uploads/2020/01/american_kestrels_web.jpg" alt="Image 8" className="slider-image" />
        </div>
        <button className="next-button" onClick={handleNext}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
        </button>

      </div>

    </>
  );
}
export default AmericanCrowdetail;