import './style.css';
import { Link, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

function Home() {

    const videoRef = useRef(null);

    useEffect(() => {
        const video = videoRef.current;

        const playVideo = () => {
            video.play().catch((error) => {
                // Autoplay was prevented
                console.log('Autoplay prevented:', error);
            });
        };

        // Add event listener for user interaction to enable autoplay
        document.addEventListener('click', playVideo);

        // Clean up the event listener on component unmount
        return () => {
            document.removeEventListener('click', playVideo);
        };
    }, []);






    const navigate = useNavigate();

    function handleClickanimal() {
        navigate('/AProductsContext');
    }
    function handleClickbird() {
        navigate('/BProductsContext');
    }
    function handleClickseeanimals() {
        navigate('/AAProductsContext');
    }
    return (

        <>
            <Helmet>
                <title>The Natural world HOME PAGE</title>
                <meta name='description' content='THE NATURAL WORLD home page'></meta>
                <link rel='canonical' href='/' />
            </Helmet>


            <div className='animalbox'>

                <div className='para-box'>
                    <h1 className='welcome'><b>THE NATURAL WORLD</b></h1>
                    <p className='textbox'>Animals are fascinating creatures that share our planet and contribute to its biodiversity.
                        From the majestic lions of the African savannah to the tiny insects inour gardens, each animal plays a unique role in the ecosystem.
                        Learning about animals can be both fun and educational, helping us appreciate and protect the natural world around us.</p>
                    <button className='animal-button' onClick={handleClickanimal}>See Animals</button>
                </div>
                <img className='hanimalpic' src="https://images.pexels.com/photos/792381/pexels-photo-792381.jpeg?auto=compress&cs=tinysrgb&w=600" alt="My Image" width={'780px'} height={'600px'} />


            </div>





            <div className='birdbox'>
                <div className='para-box2'><p>Birds are fascinating creatures that have captured the imagination of people for centuries.
                    With their colorful feathers and intricate songs, birds are a joy to watch and listen to.
                    From tiny hummingbirds to majestic eagles, there is a wide variety of bird species around the world.
                    Unfortunately, many bird species are threatened by habitat loss, climate change, and other human activities, making bird conservation efforts more important than ever.</p>
                    <button className='animal-button' onClick={handleClickbird}>See Birds</button><br/><br/>
                </div>
                <img className='hanimalpic2' src="https://images.unsplash.com/photo-1583340107401-989dbd94860a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8ZmFsY29ufGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="My Image" width={'55%'} height={'600px'} />

            </div>


            <div className='seeanimal-box'>
                <div className='para-box2'><p>Aquatic animals thrive in the enchanting depths of the world's oceans, rivers, and lakes. Their graceful movements and unique adaptations allow them to navigate through aquatic ecosystems with ease. From majestic whales and playful dolphins to colorful fish and intricate coral reefs, the diversity of aquatic life never ceases to amaze and inspire us.<br /><br />
                    <a href='https://www.highrevenuegate.com/pzquq5znf?' key={'249936fc54dc8b73c6963ef45fb62cb0'}>CLICK ONCE</a>
                </p>

                    <button className='animal-button' onClick={handleClickseeanimals}>See Aquatic Animals</button>
                </div>
                <video autoPlay muted loop className='seevideo'>
                    <source src='https://player.vimeo.com/external/344276246.sd.mp4?s=8d7a9e80cc16ddbdae48a98532e4683893737cd5&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
                </video>
            </div>





            {/* <div className='detailbox'>
                <h2>Animals</h2>
                <p>Animals are a diverse group of living organisms that are found across the world in various<br /> habitats. They include both vertebrates and invertebrates, ranging from tiny insects and <br />microscopic creatures to giant mammals like whales and elephants.<br /><br />

                    Animals are essential to the balance and function of ecosystems. They play important roles as predators, prey,<br /> and decomposers, and help maintain a healthy ecosystem. For example, bees are crucial pollinators that <br />help plants reproduce, while predators like wolves and lions help control populations of prey species.<br /><br />

                    There are an estimated 8.7 million species of animals on Earth, with new species being discovered all the <br />time. Many animals have unique adaptations that allow them to survive in their specific habitats, such as the<br /> polar bear's thick fur and blubber that help them survive in cold Arctic waters, or the chameleon's<br /> ability to change color to blend in with its surroundings.<br /><br />

                    Unfortunately, many animal species are facing threats from human activities such as habitat destruction,<br /> pollution, and climate change. Conservation efforts are crucial to protecting endangered species and <br />preserving the biodiversity of our planet.<br /><br />

                    Animals are also important to humans in many ways. They provide us with food, clothing, and medicine, and have<br /> played a significant role in human culture and mythology. They are also beloved as pets and <br />companions, and can provide emotional support and therapy to people in need.<br /><br />

                    Overall, animals are a fascinating and vital part of our world that deserves our respect, protection, and<br /> conservation efforts.<br /><br /> <hr /><br /></p>
            </div>

            <div className='detailbox'>
                <h2>Birds</h2>
                <p>Birds are a diverse group of warm-blooded animals that are characterized by their ability to<br /> fly. They are found across the world, in a wide range of habitats, including forests, <br />grasslands, deserts, and oceans. There are over 10,000 known species of birds, making them one of<br /> the most diverse groups of animals on the planet.<br /><br />

                    One of the most distinctive features of birds is their feathers. Feathers are specialized structures that are<br /> composed of a protein called keratin. They help birds regulate their body temperature, provide <br />insulation, and assist in flight. Birds also have a lightweight skeleton and powerful muscles that are <br />adapted for flying.<br /><br />

                    In addition to flying, birds have many other unique adaptations that help them survive in their environments.<br /> For example, many birds have specialized beaks and claws that allow them to capture and eat a variety of<br /> foods, including insects, seeds, fish, and small mammals. Some birds, like eagles and hawks, have <br />excellent eyesight, which they use to locate prey from great distances.<br /><br />

                    Birds also exhibit a wide range of behaviors, including complex courtship displays, territoriality, and <br />migration. Many species of birds mate for life and raise their young together, while others form large flocks<br /> that provide protection from predators and assistance in finding food.<br /><br />

                    In addition to their ecological significance, birds have also played an important role in human culture <br />throughout history. They have been depicted in art, literature, and mythology, and have been used for food,<br /> clothing, and entertainment. Today, many people enjoy bird watching as a hobby and conservation efforts<br /> are underway to protect endangered species and their habitats.<br /><br />

                    Overall, birds are a fascinating and diverse group of animals with a wide range of adaptations and behaviors.<br /> Their ability to fly and their important ecological roles make them an important and beloved part of the<br /> natural world.</p><br /><br />
            </div> */}
        </>




    );
}
export default Home;

