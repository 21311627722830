import React from 'react';
import { BrowserRouter as Router,Routes,Route } from 'react-router-dom';
import Navbar from "./components/navbar";
import Footer from './components/Footer';
import Home from './components/home';
import Contact from './components/contact';
import About from './components/about';
import Underconstruction from './components/underconstruction';
import AnimalsContextProvider from './components/animals/AProductsContext';
import BirdsContextProvider from './components/birds/BProductsContext';
import SeeAnimalsContextProvider from './components/see-animals/AAProductsContext';

// animals routes
import Antelopedetail from './components/animals/animal-details/Antelope-detail';
import Alligatordetail from './components/animals/animal-details/Alligator-detail';
import Armadillo from './components/animals/animal-details/Armadillo-detail';
import Aardvarkdetail from './components/animals/animal-details/Aardvark-detail';
import Alpacadetail from './components/animals/animal-details/Alpaca-detail';
import Anacondadetail from './components/animals/animal-details/Anaconda-detail';
import Anteaterdetail from './components/animals/animal-details/Anteater-detail';
import Elephantdetail from './components/animals/animal-details/Elephant-detail';
import Foxdetail from './components/animals/animal-details/Fox-detail';


// birds routes
import Goldfinchdetail from './components/birds/birds-details/1goldfinch';
import AmericanRobin from './components/birds/birds-details/2AmericanRobin';
import ArcticTern from './components/birds/birds-details/3ArcticTern';
import AnnaHummingbird from './components/birds/birds-details/4AnnaHummingbird';
import AtlanticPuffin from './components/birds/birds-details/5AtlanticPuffin';
import AustralianMagpie from './components/birds/birds-details/6AustralianMagpie';
import AmericanKestrel from './components/birds/birds-details/7AmericanKestrel';
import AmericanCrow from './components/birds/birds-details/8AmericanCrow';
import Alder from './components/birds/birds-details/9AlderFlycatcher';



function App() {
  return (
    <div>
      <Router>
        <Navbar/>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact/>} />

          <Route path="/underconstruction" element={<Underconstruction />} />
          <Route path="/AProductsContext" element={<AnimalsContextProvider/>} />
          <Route path="/BProductsContext" element={<BirdsContextProvider/>} />
          <Route path="/AAProductsContext" element={<SeeAnimalsContextProvider/>} />

          
          {/* animals routes */}
          <Route path="/Antelope-detail" element={<Antelopedetail/>} />
          <Route path="/Alligator-detail" element={<Alligatordetail/>} />
          <Route path="/Armadillo-detail" element={<Armadillo/>} />
          <Route path="/Aardvark-detail" element={<Aardvarkdetail/>} />
          <Route path="/Alpaca-detail" element={<Alpacadetail/>} />
          <Route path="/Anaconda-detail" element={<Anacondadetail/>} />
          <Route path="/Anteater-detail" element={<Anteaterdetail/>} />
          <Route path="/Elephant-detail" element={<Elephantdetail/>} />
          <Route path="/Fox-detail" element={<Foxdetail/>} />

          {/* birds routes */}
          <Route path="/1goldfinch" element={<Goldfinchdetail/>} />
          <Route path="/2AmericanRobin" element={<AmericanRobin/>} />
          <Route path="/3ArcticTern" element={<ArcticTern/>} />
          <Route path="/4AnnaHummingbird" element={<AnnaHummingbird/>} />
          <Route path="/5AtlanticPuffin" element={<AtlanticPuffin/>} />
          <Route path="/6AustralianMagpie" element={<AustralianMagpie/>} />
          <Route path="/7AmericanKestrel" element={<AmericanKestrel/>} />
          <Route path="/8AmericanCrow" element={<AmericanCrow/>} />
          <Route path="/9AlderFlycatcher" element={<Alder/>} />
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
