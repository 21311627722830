import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/Alpaca.png';
import { Link } from 'react-router-dom';

function Alpacadetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src="https://player.vimeo.com/progressive_redirect/playback/707531306/rendition/360p/file.mp4?loc=external&oauth2_token_id=1027659655&signature=b86f835fce7aad5c4bf7a4f207b712da68d4e7dc53394de52c33e59eab93eba5" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b> Vicugna pacos</p><br />
                    <p><b>Common Name    :</b> Alpaca</p><br />
                    <p><b>life Span      :</b> live between 15 to 20 years.</p><br />
                    <p><b>Diet           :</b> Herbivores,hay or grass</p><br />
                    <p><b>Size           :</b> 3 feet tall,<br />weigh between 100 to 200 pounds</p><br />
                    <p><b>Weight         :</b>weigh between 120 and 200 pounds (54 to 91 kilograms) </p><br />
                    <p><b>Species        :</b> There is only one species of alpaca,known as Vicugna pacos</p><br />
                    <p><b>Animal Group   :</b> The alpaca belongs to the animal group known as Camelids</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://justsomething.co/wp-content/uploads/2014/01/hilarious-alpaca-hairstyles-12.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Alpaca</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}


                    Alpacas are fascinating animals that <br />have captured the attention of people all over the world.
                    <br /> They belong to the animal group known as Camelids and<br /> are closely related to llamas, guanacos, and vicuñas. <br /><br />

                    <h3>Information about Alpaca:</h3><br />

                    The animal alpaca is native to the high Andes of South America,<br /> particularly in Peru, Bolivia, and Chile.<br /> They are well adapted to living in harsh mountainous terrain with limited resources. <br />Alpacas are social animals that live in herds and are typically bred for their luxurious fleece.<br /> They come in two varieties: Huacaya and Suri.<br /> Huacaya alpacas are the more common type and have a fluffy, crimpy fleece.<br /> Suri alpacas have long, silky,<br /> dreadlock-like fleece that hangs straight down from their bodies.<br /><br />

                    <h3>Facts About Alpaca:</h3><br />

                    Alpacas are known for their gentle and curious nature,<br /> and they make great pets and livestock.<br /> They have a lifespan of 15 to 20 years,<br /> with some living up to 25 years.<br /> Alpacas are herbivores and primarily graze on grass, but they can also eat hay and alfalfa.<br /> They have a three-chambered stomach that allows them to efficiently digest their food <br />and extract as many nutrients as possible.<br /><br />

                    <h3>Habitat:</h3><br />

                    In their natural habitat,<br /> alpacas can be found grazing in open grasslands, high up in the Andean mountains.<br /> They are well-adapted to living in extreme temperatures,<br /> with thick fleece that keeps them warm in the cold and cool in the heat.<br /> However, in other parts of the world, <br />alpacas are also kept on farms and ranches as livestock for their fiber, meat,<br /> and even as companion animals.<br /><br />

                    <h3>Farms:</h3><br />

                    Alpaca farms have become increasingly popular in recent years,<br /> as people recognize the value of their fleece and their gentle nature.<br /> Alpacas are easy to care for and require minimal space and resources.<br /> They are also known for their hardiness and resistance to disease.<br /><br />

                    <h3>Difference between Alpacas and Llamas:</h3><br />

                    Many people confuse alpacas with llamas,<br /> but there are some key differences between these two animals.<br /> Alpacas are smaller than llamas<br />, with a more rounded face and a shorter neck.<br /> They also have a softer and finer fleece than llamas,<br /> which makes their fleece more desirable for clothing and textiles.<br /> Llamas are primarily used as pack animals and are larger and stronger than alpacas.<br /><br />

                    <h3>Livestock:</h3><br />

                    Alpacas are fascinating animals that offer a unique and<br />valuable contribution to the world of livestock and pets.<br /> Whether you are looking to start an alpaca farm<br /> or simply want to learn more about these interesting creatures,<br /> there are plenty of resources available to help you get started.<br /> With their gentle nature, luxurious fleece, and hardy disposition,<br /> it's no wonder that alpacas have become such a popular and beloved animal around the world.<br /><br />

                    <h3>More Interesting Facts About Alpaca:</h3><br />

                    1.Alpacas are domesticated animals that are part of the camelid family,<br /> which also includes llamas, vicuñas, and guanacos.<br />

                    2.Alpacas are native to South America,<br /> specifically the Andean mountains of Peru, Chile, and Bolivia.<br />

                    3.Alpacas are raised for their soft, luxurious wool,<br /> which is prized for its warmth, softness, and hypoallergenic properties.<br />

                    4.There are two types of alpacas: Huacaya alpacas,<br /> which have fluffy, crimped fleece, and Suri alpacas, which have long,<br /> silky fleece that hangs in dreadlocks.<br />

                    5.Alpacas are social animals and live in herds, <br />which are led by a dominant male known as a "stud."<br /><br />

                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Antelope-detail">1<img src="https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alligator-detail">2<img src="https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Armadillo-detail">3<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Aardvark-detail">4<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">5<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">6<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">7<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">8<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://www.thoughtco.com/thmb/z_jzoK2FoMkRlHeLp-ZkCCojXG0=/5244x3744/filters:no_upscale():max_bytes(150000):strip_icc()/GettyImages-180723850-e917f13ae8ed4164a4b989a0a476c740.jpg" alt="Image 1" className="slider-image" />
                    <img src="https://www.rd.com/wp-content/uploads/2021/04/GettyImages-528127648-scaled.jpg?w=2560" alt="Image 2" className="slider-image" />
                    <img src="https://i.pinimg.com/originals/85/57/d0/8557d0535c761bf7bdc99b9b43996c28.jpg" alt="Image 3" className="slider-image" />
                    <img src="https://images.pexels.com/photos/7209078/pexels-photo-7209078.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 4" className="slider-image" />
                    <img src="https://media.istockphoto.com/photos/portrait-of-a-young-alpaca-a-south-american-mammal-picture-id964234114?k=6&m=964234114&s=612x612&w=0&h=vSe00hCDdpVC-7fcDHWKg87KQtcg0n2mWTzTDgM012g=" alt="Image 5" className="slider-image" />
                    <img src="https://images.pexels.com/photos/5503139/pexels-photo-5503139.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 6" className="slider-image" />
                    <img src="https://i.pinimg.com/originals/a4/a9/86/a4a9869e9794254064647fc9da3918b9.jpg" alt="Image 7" className="slider-image" />
                    <img src="https://media.istockphoto.com/photos/alpaca-picture-id492881830?k=6&m=492881830&s=612x612&w=0&h=Aj9T1NFVQQkN6pqkF1d1FBge46R3QICufxMpnglbSn0=" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Alpacadetail;