import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function AustralianMagpedetail() {
  const sliderRef = useRef(null);


  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Bird</p>
      </div>


      {/* add video, add video, add video */}

      <video controls width="640px" height="360px">
        <source src='https://player.vimeo.com/progressive_redirect/playback/753534426/rendition/360p/file.mp4?loc=external&oauth2_token_id=57447761&signature=e776785679fc56c4f6c276dd5ee8dfbd21638ab72c01517754432bc8e8824065' type="video/mp4" />
      </video>


      {/* img img img img img img */}

      {/* <img className='fiximg' src={fiximage} alt='img' /> */}

      <div className='spic-box'>
        <div >

          <p><b>Scientific Name:</b> Gymnorhina tibicen</p><br />
          <p><b>Common Name    :</b> Australian Magpie</p><br />
          <p><b>life Span      :</b> Up to 25 years in the wild</p><br />
          <p><b>Diet           :</b> Omnivorous diet including insects,<br /> small mammals, reptiles, and fruits</p><br />
          <p><b>Size           :</b> 37-43 cm (14-17 inches) in length</p><br />
          <p><b>Weight         :</b> 220-350 grams (7.8-12.3 ounces)</p><br />
          <p><b>Species        :</b> Gymnorhina tibicen is a member of the Artamidae family</p><br />
          <p><b>Group   :</b> Birds</p>
        </div>

        {/* circle img circle img circle img */}

        <img className='spic-img' src='https://cdn.pixabay.com/photo/2015/08/10/12/15/magpie-882642__340.jpg' alt='animal pic' />
      </div>
      <div className='detailboxx'>

        <h2>Australian Magpie</h2><br />
        <p>
          A Charming and Intelligent Songbird of Australia.<br />

          The Australian Magpie (Cracticus tibicen) is a captivating and highly intelligent bird that is<br /> native to Australia. Known for its melodious song and striking appearance, the Australian Magpie has<br /> become an iconic symbol of the Australian birdlife. In this article, we will explore<br /> various aspects of this remarkable bird, including its habitat, diet, behavior, lifespan,<br /> intelligence, and its cultural significance.<br />
          <h3>Reference:</h3><br />
          The Australian Magpie, also commonly referred to as magpie bird Australia or Aussie Magpie, is<br /> widespread throughout Australia. It can be found in a variety of habitats, including forests,<br /> woodlands, parks, and even urban areas. Magpies in Australia are known for their adaptability and<br /> can thrive in different environments, making them a familiar sight to both city dwellers and those<br /> living in rural areas.<br />
          <h3>Scientific Name:</h3><br />
          Scientifically known as Cracticus tibicen, the Australian Magpie belongs to the family Artamidae,<br /> which includes several other bird species native to Australia. The magpie bird Australia is known<br /> for its striking black and white plumage, with a distinctive black hood and white belly.<br /> The magpie's wings display a mix of black and white feathers, creating a beautiful pattern.<br />
          <h3>Diet:</h3><br />
          The diet of Australia magpies is varied and includes both animal and plant matter. They are<br /> omnivorous birds and feed on a wide range of food items such as insects, worms, small reptiles,<br /> fruits, seeds, and even human scraps. Their foraging behavior is characterized by probing the ground<br /> with their sharp beaks or swooping down from perches to catch prey in flight.<br />
          <h3>Lifespan:</h3><br />
          The Australian Magpie has an average lifespan of around 20 years in the wild, although some<br /> individuals have been known to live much longer. Their longevity can be attributed to their ability<br /> to adapt to different habitats and their cautious nature, which helps them avoid<br /> predators and survive in challenging environments.<br />
          <h3>Australian Magpie known as intelligent bird:</h3><br />
          In terms of intelligence, the Australian Magpie is considered one of the most intelligent bird<br /> species in the world. They are known for their complex vocalizations, including a melodious song<br /> that is often heard in the early morning or during breeding season. Australian magpies are also<br /> highly social birds and form strong family bonds. They have a sophisticated social structure, with<br /> dominant individuals defending territories and younger birds helping to raise their<br /> siblings.<br />
          <h3>About Australian Magpie:</h3><br />
          The Australian Magpie holds cultural significance in Aboriginal and Torres Strait Islander<br /> communities, where it is regarded as a symbol of wisdom and protection. In Australian folklore, the<br /> magpie is believed to bring good luck when seen or heard, while a magpie's nest is<br /> associated with home and family.<br />
          <h3>Not aggressive towards human:</h3><br />
          While Australian magpies are generally not aggressive towards humans, there have been instances<br /> where they can become territorial and exhibit defensive behavior, particularly during the breeding<br /> season. It is important to be cautious and respectful when encountering magpies during<br /> this time. Wearing a hat or carrying an umbrella can provide protection from swooping<br /> attacks, as magpies tend to defend their nests vigorously.<br />
          <h3>What to feed Australian magpies?</h3><br />

          Australian magpies have a varied diet and can be fed a combination of fruits, seeds, insects, and<br /> even small scraps of meat. However, it's important to note that providing supplementary food<br /> should be done sparingly and should not replace their natural foraging behavior.<br />
          <h3>Where do Australian magpies sleep at night?</h3><br />

          Australian magpies typically sleep in trees, perched on branches or in the dense foliage. They<br /> prefer elevated roosting spots to keep a lookout for potential threats.<br />
          <h3>What do Australian magpies eat?</h3><br />

          Australian magpies have a diverse diet that includes insects, worms, small reptiles, fruits, seeds,<br /> and human scraps.<br />
          <h3>How long do Australian magpies live?</h3><br />

          The average lifespan of Australian magpies in the wild is around 20 years, although some individuals<br /> have been known to live longer.<br />
          <h3>What is a group of Australian magpies called?</h3><br />

          A group of Australian magpies is called a "murder," "tidings," or "tribe."<br />
          <h3>Are Australian magpies corvids?</h3><br />
          No, Australian magpies are not corvids. They belong to the family Artamidae, which includes the<br /> butcherbirds and currawongs.<br />
          <h3>Do Australian magpies kill each other?</h3><br />
          While territorial disputes among magpies can occur, resulting in aggression and occasional injuries,<br /> fatal attacks among adult magpies are rare.<br />
          <h3>Are Australian magpies dangerous?</h3><br />
          Australian magpies can become territorial and exhibit defensive behavior during the breeding season,<br /> which may include swooping at perceived threats. While it can be intimidating, they rarely<br /> cause serious harm. Taking precautions such as wearing a hat or using an umbrella can help deter<br /> swooping attacks.<br />
          <h3>Are Australian magpies endangered?</h3><br />
          Australian magpies are not considered endangered. They are a common and widespread species<br /> throughout Australia.<br />
          <h3>Are Australian magpies intelligent?</h3><br />

          Yes, Australian magpies are highly intelligent birds known for their complex vocalizations,<br /> problem-solving abilities, and sophisticated social behaviors. Their intelligence has been the<br /> subject of scientific research and has contributed to their adaptability and success as a species.<br />
          <h3>Conclusion:</h3><br />
          In conclusion, the Australian Magpie is a captivating and intelligent bird that holds a special<br /> place in the hearts of Australians. With its beautiful plumage, melodious song, and adaptable<br /> nature, the magpie bird Australia is a beloved symbol of Australia's rich avian diversity.<br />

        </p>
      </div>

      {/* slider code */}

      <div className="slider-container">
        <button className="prev-button" onClick={handlePrev}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
        </button>
        <div className="slider" ref={sliderRef}>
          <img src="https://hapalochlaena.files.wordpress.com/2011/05/magpie-01.jpg" alt="Image 1" className="slider-image" />
          <img src="https://en.academic.ru/pictures/enwiki/77/Magpie_inflight.jpg" alt="Image 2" className="slider-image" />
          <img src="https://www.geekygirlengineer.com/wp-content/uploads/2012/08/18-Australia-Magpie.jpg" alt="Image 3" className="slider-image" />
          <img src="https://i0.wp.com/gplama.com/wp-content/uploads/2020/10/Screen-Shot-2020-10-03-at-11.41.18-am.png?ssl=1" alt="Image 4" className="slider-image" />
          <img src="https://i.pinimg.com/736x/a5/1c/23/a51c23b30e0e108bfaf6e5d33422c68a--attractive-people-twos-company.jpg" alt="Image 5" className="slider-image" />
          <img src="https://1.bp.blogspot.com/-mD0jOEdqPLU/V9rYevMGtWI/AAAAAAAARZQ/RkAz9SGNzPg5CQcj5A5VMxFegOfG-eMsgCLcB/s1600/pie.jpg" alt="Image 6" className="slider-image" />
          <img src="https://i1171.photobucket.com/albums/r548/retroclassics/157639-sepik_zpsfcc6e437.jpg" alt="Image 7" className="slider-image" />
          <img src="https://sl.sbs.com.au/public/image/file/4f58788b-039c-48b1-835a-7084b075083a" alt="Image 8" className="slider-image" />
        </div>
        <button className="next-button" onClick={handleNext}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
        </button>

      </div>

    </>
  );
}
export default AustralianMagpedetail;