import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/Anteater.jpg';
import { Link } from 'react-router-dom';


function Anteaterdetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src='https://player.vimeo.com/external/637382537.sd.mp4?s=19e1f72893b91e56e85dc8bd2acdb75ff48b1a35&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b>
                        <br />  1.Giant Anteater: Myrmecophaga tridactyla<br />
                        2.Silky Anteater: Cyclopes didactylus<br />
                        3.Northern Tamandua: Tamandua mexicana<br />
                        4.Southern Tamandua: Tamandua tetradactyla</p><br />
                    <p><b>Common Name    :</b> Anteater</p><br />
                    <p><b>life Span      :</b>  <br />
                        1.giant anteaters(25 years)<br />
                        2.Silky Anteater(14 years)<br />
                        3.Northern Tamandua(15 years)<br />
                        4.Southern Tamandua(10 years)</p><br />
                    <p><b>Diet           :</b> insectivores(ants, termites, and other small insects )</p><br />
                    <p><b>Size           :</b>  <br />
                        1.Giant Anteater<br />(2 meters (6.5 feet) long,<br />weigh between 33 and 66 kilograms (73-145 pounds))<br />
                        2.Silky Anteater<br />(50 centimeters (20 inches) in length,<br />weighing only about 400 grams (less than a pound))<br />
                        3.Northern Tamandua<br />(1 meter (3.3 feet) long ,<br />weigh between 2 and 6 kilograms (4.4-13.2 pounds))<br />
                        4.Southern Tamandua<br />(1.2 meters (4 feet) long ,<br /> weigh between 3 and 8 kilograms (6.6-17.6 pounds))</p><br />
                    <p><b>Species        :</b> <br />
                        1.Giant Anteater (Myrmecophaga tridactyla)<br />
                        2.Silky Anteater (Cyclopes didactylus)<br />
                        3.Northern Tamandua (Tamandua mexicana)<br />
                        4.Southern Tamandua (Tamandua tetradactyla)</p><br />
                    <p><b>Animal Group   :</b> Xenarthra</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://animals.sandiegozoo.org/sites/default/files/inline-images/giant_anteater_adult_0.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Anteater</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}

                    Anteaters are fascinating mammals known for their long snouts, sharp claws, and<br /> unique diets. Despite being an unusual and somewhat mysterious animal, they do <br />indeed still exist in the world today.<br /><br />
                    <h3> misconception about anteater</h3><br />
                    One common misconception about anteaters is that they only eat ants. While it's <br />true that their diet is primarily made up of ants and termites, they may<br /> also eat other insects and occasionally small reptiles or mammals. Anteaters <br />are uniquely adapted to eating ants and termites due to their long, sticky<br /> tongues, which can extend up to two feet in length.<br></br>
                    <h3> But why do anteaters eat ants? </h3><br />
                    For one, these insects are abundant and easy to <br />catch. Anteaters also have a specialized digestive system that allows them <br />to break down the tough exoskeletons of ants and termites. Additionally,<br /> these insects provide a high amount of protein, which is essential for the <br />anteater's survival.<br /><br />
                    <h3> Species of anteater </h3><br />
                    There are four species of anteaters, each with their own unique characteristics.<br /> The largest species is the giant anteater, which can grow up to 2 meters <br />long and weigh up to 145 pounds. The smallest species is the silky <br />anteater, which weighs less than a pound and can fit in the palm of a hand. The <br />northern and southern tamandua are medium-sized anteaters with prehensile <br />tails that they use for climbing.<br /><br />
                    <h3> Animal Group of Anteater: </h3><br />
                    Anteaters are part of the animal group known as Xenarthra, which also includes <br />armadillos and sloths. They are mostly found in Central and South America,<br /> where they inhabit a range of habitats including forests, grasslands, and <br />savannas. Despite their unique adaptations and important role in their<br /> ecosystem, some species of anteaters are endangered due to habitat loss,<br /> poaching, and other human-caused factors.<br /><br />
                    <h3> Scientific Name of Anteater: </h3><br />
                    The scientific name for the silky anteater is Cyclopes didactylus, while the giant anteater's <br />scientific name is Myrmecophaga tridactyla. Although they may have some physical similarities to<br /> rodents, anteaters are not actually rodents. They are classified as their own order, which<br /> includes the four species of anteaters and their extinct relatives.<br /><br />
                    <h3> powerful claws: </h3><br />
                    Anteaters have powerful claws that they use for digging into ant and termite mounds.<br /> Their claws are long and curved, which allows them to easily tear apart the hard dirt and insect nests.<br /> However, despite their sharp claws, anteaters are generally not aggressive and prefer to avoid<br /> conflict.<br /><br />
                    <h3> conclusion: </h3><br />
                    In conclusion,<br /> Anteaters are a unique and fascinating group of mammals that have adapted to eating<br /> ants and termites. They are part of the Xenarthra animal group and are found in Central<br /> and South America. Although some species are endangered, efforts are being made to<br /> protect and conserve these important animals.<br /><br />



                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Alligator-detail">1<img src="https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Armadillo-detail">2<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Aardvark-detail">3<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">4<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">5<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">6<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">7<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">8<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://cdn.pixabay.com/photo/2021/09/26/11/56/animal-6657488_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>

                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://www.thoughtco.com/thmb/Q0pVdPu6EAXrNK8ktXHyvCRpwSY=/2250x1500/filters:no_upscale():max_bytes(150000):strip_icc()/148307334-56a008a45f9b58eba4ae8fb1.jpg" alt="Image 1" className="slider-image" />
                    <img src="https://i.natgeofe.com/n/89bd7416-da3b-41dc-94c2-fcbf7e0bcb3a/67089.jpg" alt="Image 2" className="slider-image" />
                    <img src="https://3.bp.blogspot.com/-EetvDX_bhYc/UBeLsLSAPrI/AAAAAAAANdU/pZ-kVpQhAhs/s1600/Anteater.jpg" alt="Image 3" className="slider-image" />
                    <img src="https://i.pinimg.com/736x/09/10/b9/0910b9d750ba068e5f7ca71c04b41262.jpg" alt="Image 4" className="slider-image" />
                    <img src="https://brevardzoo.org/wp-content/uploads/GiantAnteater5.jpg" alt="Image 5" className="slider-image" />
                    <img src="https://img2.thejournal.ie/inline/456123/original/?height=528" alt="Image 7" className="slider-image" />
                    <img src="https://geozoo.org/wp-content/uploads/2018/10/anteater-on-grass-geozoo.org_-560x420.jpg" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Anteaterdetail;