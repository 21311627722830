import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/alligator.png';
import { Link } from 'react-router-dom';


function Alligatordetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src='https://player.vimeo.com/external/543813393.sd.mp4?s=572f8f8be0239cca200a348bb4722491f98a400a&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div>

                    <p><b>Scientific Name:</b> Alligator mississippiensis</p><br />
                    <p><b>Common Name    :</b> Alligator</p><br />
                    <p><b>life Span      :</b> Alligator35-50 years</p><br />
                    <p><b>Diet           :</b> Carnivorous - primarily feed on fish, turtles, and mammals</p><br />
                    <p><b>Size           :</b> up to 14 feet (4.3 meters)</p><br />
                    <p><b>Weight         :</b> 1,000 pounds (450 kg)</p><br />
                    <p><b>Species        :</b> Two (Alligator mississippiensis,Alligator sinensis)</p><br />
                    <p><b>Animal Group   :</b> Alligatoridae</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://images.freeimages.com/images/previews/a61/nile-crocodile-4-1336965.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Alligator</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}

                    Alligators are large reptiles that are found primarily in freshwater habitats such as<br /> swamps, rivers, and lakes in the southeastern United States and eastern China. They are<br /> closely related to crocodiles and belong to the family Alligatoridae. In this article,<br /> we will discuss the biology, behavior, and ecology of alligators in detail.<br /><br />

                    <h3>Anatomy:</h3><br />

                    Alligators have a distinctive appearance with a long, broad snout, powerful jaws, and a<br /> heavy, armored body. They are covered in thick, bony plates called osteoderms that<br /> provide protection from predators and environmental hazards. Alligators are cold-blooded <br />animals, which means their body temperature is regulated by their environment. They<br /> are more active in warm weather and less active in colder temperatures.<br /><br />

                    <h3>Diet:</h3><br />

                    Alligators are carnivorous and their diet consists mainly of fish, turtles, birds, and <br />mammals. They have a powerful bite force and sharp teeth that they use to catch and kill <br />their prey. Alligators are opportunistic feeders and will consume any animal they can<br /> overpower, including deer, wild boar, and even smaller alligators.<br /><br />

                    <h3>Behavior:</h3><br />

                    Alligators are typically solitary animals, with males and females coming together only <br />during the breeding season. They are territorial and will defend their space aggressively<br /> if threatened. Alligators are also known for their ability to regulate <br />their body temperature by basking in the sun on the banks of rivers and lakes. They <br />are most active during the day, but will sometimes hunt at night.<br /><br />

                    <h3>Reproduction:</h3><br />

                    Alligators reach sexual maturity at around 10 years of age. Mating season typically occurs<br /> in late spring or early summer, with females laying their eggs in a nest made<br /> of vegetation and mud. The incubation period lasts approximately 60 days, after<br /> which the young hatch and make their way to the water. Alligators are <br />protective parents and will guard their young from predators.<br /><br />

                    <h3>Ecology:</h3><br />

                    Alligators play an important role in their ecosystems. They help to control populations of<br /> other animals, such as fish and turtles, and contribute to nutrient cycling by<br /> consuming carrion. Alligators are also a source of food for other predators, such as<br /> eagles and bears.<br /><br />

                    <h3>Conservation:</h3><br />

                    Alligators were once hunted heavily for their hides, meat, and other body parts. However,<br /> conservation efforts have helped to bring their populations back from the brink of <br />extinction. Today, alligators are protected by law and hunting is strictly regulated.<br /> While their populations are stable, alligators are still threatened by habitat loss<br /> and other human activities.<br /><br />

                    <h3>Conclusion:</h3><br />

                    Alligators are fascinating creatures that have captivated the human imagination for<br /> centuries. With their distinctive appearance, powerful jaws, and remarkable behaviors,<br /> alligators are an important part of the natural world. While they can be dangerous if<br /> provoked or cornered, alligators generally pose little threat to humans if left alone.<br /> By learning more about these magnificent animals, we can better appreciate and<br /> protect them for generations to come.<br /><br />

                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Antelope-detail">1<img src="https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Armadillo-detail">2<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Aardvark-detail">3<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">4<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">5<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">6<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">7<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">8<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://plus.unsplash.com/premium_photo-1667667845996-c8f8f4719ac7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MXx8YWxsaWdhdG9yfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="Image 1" className="slider-image" />
                    <img src="https://images.unsplash.com/photo-1581131045613-5b53b4593478?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OHx8YWxsaWdhdG9yfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60" alt="Image 2" className="slider-image" />
                    <img src="https://images.unsplash.com/photo-1614065613125-17553fbc59f6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTZ8fGFsbGlnYXRvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="Image 3" className="slider-image" />
                    <img src="https://images.unsplash.com/photo-1523917631658-047ed87b47fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fGFsbGlnYXRvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="Image 4" className="slider-image" />
                    <img src="https://images.unsplash.com/photo-1594201791603-62f33647ce10?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fGFsbGlnYXRvcnxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60" alt="Image 5" className="slider-image" />
                    <img src="https://media.istockphoto.com/id/939321040/photo/beach-vacation-destination-everglades-florida.jpg?b=1&s=612x612&w=0&k=20&c=JA1sPhz7-mkWv2Xyth5UZ1FMk5BuDiOMGg42baQ27Y4=" alt="Image 6" className="slider-image" />
                    <img src="https://images.freeimages.com/images/previews/b24/nile-crocodile-3-1342975.jpg" alt="Image 7" className="slider-image" />
                    <img src="https://images.freeimages.com/images/previews/248/crocodile-1340779.jpg" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Alligatordetail;