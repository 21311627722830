import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/Elephant.png';
import { Link } from 'react-router-dom';


function Elephantdetail() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src='https://player.vimeo.com/external/355530296.sd.mp4?s=2efff019a1399a4634ad74a9f465d80caa57d650&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' width={400} />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b><br />African elephant(Loxodonta africana)
                        <br />Asian elephant(Elephas maximus)</p><br />
                    <p><b>Common Name    :</b>Elephant</p><br />
                    <p><b>life Span      :</b> 70 years(maximum recorded age 86 years)</p><br />
                    <p><b>Diet           :</b> herbivores(grasses, leaves, fruits <br /> ,bark and roots)</p><br />
                    <p><b>Size           :</b><br />African Elephant(3.5 meters (11 feet) tall)<br />Asian Elephant(3 meters (10 feet) tall ) </p><br />
                    <p><b>Weight         :</b>African Elephant(weighing up to 5,500 kg (12,000 pounds))<br />Asian Elephant(weighing up to 5,500 kg (12,000 pounds)) </p><br />
                    <p><b>Species        :</b> <br />African Elephant (Loxodonta africana)<br />Asian Elephant (Elephas maximus)</p><br />
                    <p><b>Animal Group   :</b>pachyderms</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://2.bp.blogspot.com/-6f2mFXyrboo/VxFcz4Oh_oI/AAAAAAAAFYM/nhU-r8eVcVQgTj0aZVrjtM4TV8CidqjVgCKgB/s1600/Elephant-Animal-HD-Wallpapers.jpg' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Elephant</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}

                    Elephants are majestic and intelligent creatures that have captured the hearts<br /> of people all around the world. These gentle giants are the largest<br /> land animals on Earth and are known for their incredible memory and<br /> strong social bonds. facts about elephants, their habitat, scientific names, weight,<br /> and diet.<br /><br />
                    <h3>Facts About Elephant:</h3><br />
                    1.	Elephants are part of the family Elephantidae, which consists of two living species: the African<br /> elephant (Loxodonta africana) and the Asian elephant (Elephas maximus).<br />
                    2.	Elephants are herbivores, meaning they only eat plants. Their diet consists of grasses, leaves,<br /> bark, fruits, and vegetables.<br />
                    3.	What's special about elephants is their incredible memory. They have been known to remember<br /> other elephants and humans that they haven't seen for years, and even remember places and routes<br /> they haven't visited for decades.<br />
                    4.	Elephants are so smart because their brain is the largest of any land animal, weighing up to 5<br /> kilograms in adult males.<br />
                    5.	Elephants are the largest land animals on Earth. African elephants can weigh up to 5,500 kg (12,<br />000 lb) and stand up to 3.3 meters (11 feet) tall at the shoulder. Asian elephants are slightly<br /> smaller, weighing up to 5,000 kg (11,000 lb) and standing up to 2.7 meters (9 feet)<br /> tall.<br />
                    6.	African elephants are divided into two subspecies: the savannah elephant (Loxodonta africana<br /> africana) and the forest elephant (Loxodonta cyclotis).<br />
                    7.	Elephants are known for their distinctive ears, which are shaped like the African continent. The<br /> ears are used for cooling their body temperature and also for communication.<br />
                    8.	The habitat of elephants varies depending on the species. African elephants are found in<br /> savannahs, forests, and deserts, while Asian elephants are found in tropical and subtropical forests.<br />
                    9.	Elephant conservation is essential as these magnificent creatures face threats from habitat <br />loss, poaching, and human-elephant conflict. Many organizations are working towards protecting<br /> elephant populations and their habitats.<br />
                    10.	While it is illegal to own an elephant as a pet or for entertainment purposes, some countries<br /> still have elephant for sale markets. It is important to remember that elephants are wild animals<br /> that belong in their natural habitats and not in captivity.<br />
                    <h3>Conclusion:</h3><br />
                    In conclusion, elephants are incredible creatures with fascinating characteristics and unique<br /> abilities. Their intelligence, memory, and social bonds make them a beloved animal worldwide. We<br /> must protect these gentle giants and ensure their survival for generations to come.<br />
                    Elephants are native to a variety of habitats, including savannas, forests, and deserts, across<br /> Africa and Asia. African elephants are found in 37 countries on the continent, while Asian elephants<br /> can be found in 13 countries throughout Asia.<br />
                    African elephants inhabit areas with a mix of grasslands, woodlands, and forests, while Asian <br />elephants can be found in tropical and subtropical forests. Elephants are known to require a large<br /> amount of space to thrive, and their habitats typically feature abundant sources of food,<br /> water, and shelter.<br />
                    <h3>Habitat:</h3><br />
                    In addition to these natural habitats, elephants also sometimes live in human-modified landscapes<br /> such as agricultural fields and plantations. However, these environments may not always provide<br /> adequate resources for the animals, leading to conflicts between elephants and humans.<br />
                    <h3>Types of Elephants:</h3><br />
                    There are three recognized species of elephants:<br />
                    1.	African bush elephant (Loxodonta africana): The African bush elephant is the largest of the<br /> three species, with males weighing up to 12,000 pounds (5,400 kg) and standing up to 13 feet (4 <br />meters) tall at the shoulder. They are found in savannas, forests, and deserts across sub-Saharan<br /> Africa.<br />
                    2.	African forest elephant (Loxodonta cyclotis): The African forest elephant is smaller than the <br />African bush elephant, with males weighing up to 6,600 pounds (3,000 kg) and standing up to 8<br /> feet (2.5 meters) tall at the shoulder. They are found in the dense forests of Central and<br /> West Africa.<br />
                    3.	Asian elephant (Elephas maximus): The Asian elephant is smaller than both African species, with<br /> males weighing up to 11,000 pounds (5,000 kg) and standing up to 9 feet (2.75 meters) tall<br /> at the shoulder. They are found in various habitats across Asia, including grasslands,<br /> forests, and scrublands.<br />
                    It's important to note that there are also several subspecies within each of these three species, <br />which vary in size, coloration, and other physical characteristics.<br />


                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Armadillo-detail">1<img src="https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Aardvark-detail">2<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">3<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">4<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">5<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">6<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">7<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">8<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2021/09/26/11/56/animal-6657488_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://cdn.pixabay.com/photo/2018/11/13/15/25/barbary-ape-3813278_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>

                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://www.armadatekstil.net/wp-content/uploads/2014/04/african-bush-elephant-habitat.jpg" alt="Image 1" className="slider-image" />
                    <img src="https://www.karibueastafrica.com/wp-content/uploads/2017/11/facts-elephants.jpg" alt="Image 2" className="slider-image" />
                    <img src="https://media.istockphoto.com/photos/indian-elephant-taking-a-bath-and-spraying-water-picture-id513554212?k=6&m=513554212&s=612x612&w=0&h=aHQrqlTbKe-tEIeOLyCzt2ygijMOfn7GMJ6HmhKDcfI=" alt="Image 3" className="slider-image" />
                    <img src="https://1.bp.blogspot.com/-A-xTHwhC_bo/UXc0eR7QsfI/AAAAAAAAESA/59odrWDXABg/s1600/baby_elephant.jpg" alt="Image 4" className="slider-image" />
                    <img src="https://media.istockphoto.com/photos/elephants-picture-id535196301?k=6&m=535196301&s=170667a&w=0&h=BbKuOD9jxeCQ5JGONCc1C35erB9hpLLgmyqohsx0BIs=" alt="Image 5" className="slider-image" />
                    <img src="https://www.howitworksdaily.com/wp-content/uploads/2015/05/tanzania_elephants3.jpg" alt="Image 6" className="slider-image" />
                    <img src="https://2.bp.blogspot.com/_N_mOB63qPaE/TMHB1H7WSKI/AAAAAAAAQcc/QN1zgs0RKts/s1600/Baby--African-Elephant-Picture-Photo.jpg" alt="Image 7" className="slider-image" />
                    <img src="https://www.all-free-photos.com/images/animaux-2/PI82281-hr.jpg" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Elephantdetail;