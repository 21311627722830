import React, { useRef, useEffect } from 'react';
import './birdsdetailstyle.css';

function Goldfinchdetail() {
  const sliderRef = useRef(null);


  useEffect(() => {
    const sliderInterval = setInterval(() => {
      if (sliderRef.current) {
        const slider = sliderRef.current;
        const sliderWidth = slider.offsetWidth;
        const currentScroll = slider.scrollLeft;
        const maxScroll = slider.scrollWidth - sliderWidth;

        if (currentScroll >= maxScroll) {
          // When reaching the last image, scroll back to the first image
          slider.scrollTo({
            left: 0,
            behavior: 'smooth',
          });
        } else {
          // Scroll to the next image
          slider.scrollBy({
            left: sliderWidth,
            behavior: 'smooth',
          });
        }
      }
    }, 5000);

    return () => {
      clearInterval(sliderInterval);
    };
  }, []);

  const handlePrev = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;

      if (currentScroll === 0) {
        // When on the first image, scroll to the last image
        slider.scrollTo({
          left: slider.scrollWidth,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the previous image
        slider.scrollBy({
          left: -sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      const slider = sliderRef.current;
      const sliderWidth = slider.offsetWidth;
      const currentScroll = slider.scrollLeft;
      const maxScroll = slider.scrollWidth - slider.offsetWidth;

      if (currentScroll >= maxScroll) {
        // When reaching the last image, scroll back to the first image
        slider.scrollTo({
          left: 0,
          behavior: 'smooth',
        });
      } else {
        // Scroll to the next image
        slider.scrollBy({
          left: sliderWidth,
          behavior: 'smooth',
        });
      }
    }
  };




  return (
    <>
      <div className='arrow-heading'>
        <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
        <p>Bird</p>
      </div>


      {/* add video, add video, add video */}

      <video controls width="640px" height="360px">
        <source src='https://player.vimeo.com/external/422718858.hd.mp4?s=ecaa53e62f1e2c428a071508849209e17e90926d&profile_id=174' type="video/mp4" />
      </video>


      {/* img img img img img img */}

      {/* <img className='fiximg' src={fiximage} alt='img' /> */}

      <div className='spic-box'>
        <div>
          <p><b>Scientific Name:</b> Carduelis carduelis</p><br />
          <p><b>Common Name    :</b> European Goldfinch or<br /> just Goldfinch</p><br />
          <p><b>life Span      :</b> 3-4 years</p><br />
          <p><b>Diet           :</b> Primarily seeds, insects during breeding season</p><br />
          <p><b>Size           :</b> 11-13 cm (4-5 inches) in length</p><br />
          <p><b>Weight         :</b> 14-19 grams (0.5-0.7 ounces)</p><br />
          <p><b>Species        :</b> Carduelis carduelis is a member of the finch family Fringillidae</p><br />
          <p><b>Group   :</b> Birds</p>
        </div>

        {/* circle img circle img circle img */}

        <img className='spic-img' src='https://images.unsplash.com/photo-1616861539282-b468a5441765?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Z29sZGZpbmNofGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=400&q=60' alt='animal pic' />
      </div>
      <div className='detailboxx'>

        {/* animal name h2 */}

        <h2>Gold Finch Bird</h2><br />
        <p>
          The goldfinch is a beautiful bird that is often found in gardens and parks, <br />especially in the UK and Pakistan. It belongs to the family of finches and is known<br /> for its bright yellow and black plumage, as well as its sweet, melodic <br />song. In this article, we will explore the various aspects of goldfinches, <br />including their appearance, behavior, habitat, and sounds.<br />
          <h3>Appearance:</h3><br />
          The goldfinch is a small bird that measures about 12-13 cm in length and weighs around 14-19 grams. <br />Its bright yellow and black plumage is easily recognizable, with black wings and tail feathers,<br /> a white rump, and a red face. The female goldfinch is similar in appearance, but with less <br />intense coloring and no red on the face. During the breeding season, the male goldfinch may have a <br />brighter red face and more prominent black markings.<br />

          <h3>Behavior:</h3><br />
          Goldfinches are active and sociable birds that often travel in flocks. They are acrobatic flyers and<br /> can often be seen perched on flower heads or hanging upside down to feed. They are <br />seed-eaters and prefer to feed on thistle and teasel seeds, but will also eat small insects and<br /> spiders. During the breeding season, goldfinches build cup-shaped nests in trees or bushes and lay<br /> 4-6 eggs.<br />

          <h3>Habitat:</h3><br />
          Goldfinches are found in a variety of habitats, including woodland edges, hedgerows, parks, gardens,<br /> and farmland. They are common in the UK and are also found in Pakistan, where they are known<br /> as the "Chirring" bird. In Pakistan, goldfinches are often kept as pets for their<br /> beautiful appearance and sweet song.<br />

          <h3>Sounds:</h3><br />
          The goldfinch is known for its sweet, melodic song, which is often described as a "tinkling" or<br /> "trilling" sound. The male goldfinch has a more elaborate song than the female, which is used to<br /> attract mates and defend territories. Goldfinches also have a variety of calls, including a<br /> high-pitched "tsip" call and a soft, chattering call.<br />
          <h3>Injured goldfinches:</h3><br />
          If you come across an injured goldfinch, it's important to contact a local wildlife rehabilitation<br /> center or bird sanctuary for help. Goldfinches, like all wild birds, are <br />protected by law, and it's illegal to keep them as pets or to interfere with their nests or young.<br />

          <h3>Facts:</h3><br />

          1.Goldfinches are sometimes referred to as "thistle finches" because they are often found feeding on <br />thistle seeds.<br />
          2.In the UK, goldfinches were once considered a pest because they fed on crops, but they are now a <br />protected species.<br />
          3.Goldfinches have a unique way of drinking, using their tongues to suck up water rather than tipping <br />their heads back like most birds.<br />
          4.Goldfinches have been observed using tools, such as sticks, to extract food from crevices.<br />
          The goldfinch is the national bird of Israel.<br />
          <h3>Conclusion:</h3><br />
          In conclusion, goldfinches are fascinating birds that are beloved for their beauty and sweet song.<br /> Whether you encounter them in your garden, at a park, or in the wild, they are sure to delight<br /> with their acrobatic flights and cheerful presence. If you're lucky enough to hear the<br /> tinkling sound of a goldfinch's song, take a moment to appreciate this beautiful bird and<br /> all the wonders of nature.<br />

        </p>
      </div>

      {/* slider code */}

      <div className="slider-container">
        <button className="prev-button" onClick={handlePrev}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
        </button>
        <div className="slider" ref={sliderRef}>
          <img src="https://cdn.birdwatchingdaily.com/2016/07/809.jpg" alt="Image 1" className="slider-image" />
          <img src="https://cdn.birdwatchingdaily.com/2016/06/2921.jpg" alt="Image 2" className="slider-image" />
          <img src="https://www.boomermagazine.com/wp-content/uploads/2020/07/AmericanGoldfinch2.jpg" alt="Image 3" className="slider-image" />
          <img src="https://www.birdsandblooms.com/wp-content/uploads/2015/03/DSC04175.jpg" alt="Image 4" className="slider-image" />
          <img src="https://www.itsnature.org/wp-content/uploads/2010/10/American_Goldfinch-275271.jpg" alt="Image 5" className="slider-image" />
          <img src="https://images.freeimages.com/images/large-previews/a09/american-goldfinches-1372688.jpg" alt="Image 6" className="slider-image" />
          <img src="https://www.singing-wings-aviary.com/wp-content/uploads/2015/02/Flying-European-Goldfinch.jpg" alt="Image 7" className="slider-image" />
          <img src="https://www.publicdomainpictures.net/pictures/380000/velka/american-goldfinch.jpg" alt="Image 8" className="slider-image" />
        </div>
        <button className="next-button" onClick={handleNext}>
          <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
        </button>

      </div>

    </>
  );
}
export default Goldfinchdetail;