import React, { useRef, useEffect } from 'react';
import './animaldetailstyle.css';
import fiximage from './fix-photos/armadillo.png';
import { Link } from 'react-router-dom';


function Armadillo() {
    const sliderRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
        const sliderInterval = setInterval(() => {
            if (sliderRef.current) {
                const slider = sliderRef.current;
                const sliderWidth = slider.offsetWidth;
                const currentScroll = slider.scrollLeft;
                const maxScroll = slider.scrollWidth - sliderWidth;

                if (currentScroll >= maxScroll) {
                    // When reaching the last image, scroll back to the first image
                    slider.scrollTo({
                        left: 0,
                        behavior: 'smooth',
                    });
                } else {
                    // Scroll to the next image
                    slider.scrollBy({
                        left: sliderWidth,
                        behavior: 'smooth',
                    });
                }
            }
        }, 5000);

        return () => {
            clearInterval(sliderInterval);
        };
    }, []);

    const handlePrev = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;

            if (currentScroll === 0) {
                // When on the first image, scroll to the last image
                slider.scrollTo({
                    left: slider.scrollWidth,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the previous image
                slider.scrollBy({
                    left: -sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };

    const handleNext = () => {
        if (sliderRef.current) {
            const slider = sliderRef.current;
            const sliderWidth = slider.offsetWidth;
            const currentScroll = slider.scrollLeft;
            const maxScroll = slider.scrollWidth - slider.offsetWidth;

            if (currentScroll >= maxScroll) {
                // When reaching the last image, scroll back to the first image
                slider.scrollTo({
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                // Scroll to the next image
                slider.scrollBy({
                    left: sliderWidth,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (
        <>
            <div className='arrow-heading'>
                <img src={process.env.PUBLIC_URL + `/icon/right-filled-arrow.png`} style={{ marginRight: '20px', marginLeft: '20px' }} className='arrow' />
                <p>Animal</p>
            </div>


            {/* add video, add video, add video */}

            <video controls>
                <source src='https://player.vimeo.com/external/457123157.sd.mp4?s=a84750c33828a74d80e39f2485357ae10aa81bc2&profile_id=164&oauth2_token_id=57447761' type="video/mp4" />
            </video>


            {/* img img img img img img */}

            <img className='fiximg' src={fiximage} alt='img' />

            <div className='spic-box'>
                <div >

                    <p><b>Scientific Name:</b> Dasypus novemcinctus</p><br />
                    <p><b>Common Name    :</b> Nine-banded armadillo</p><br />
                    <p><b>life Span      :</b> 12-15 years</p><br />
                    <p><b>Diet           :</b>  Omnivorous (insects, small vertebrates, plants, and fruits)</p><br />
                    <p><b>Size           :</b>  length 38-58 cm, tail length 26-47 cm</p><br />
                    <p><b>Weight         :</b>  3-6 kg</p><br />
                    <p><b>Species        :</b> Dasypus novemcinctus is the only species</p><br />
                    <p><b>Animal Group   :</b> Mammal</p>
                </div>

                {/* circle img circle img circle img */}

                <img className='spic-img' src='https://cdn.pixabay.com/photo/2018/01/05/12/36/devotional-battleship-3062825__340.jpg' alt='animal pic' />
            </div>

            <div className='detailboxx'>

                {/* animal name h2 */}

                <h2>Armadillo</h2><br />
                <p>
                    {/* use h3 for heading inside p tag */}
                    Armadillos are fascinating creatures that are known for their tough, armored shells that<br /> protect them from predators. In this article, we will discuss various aspects of<br /> armadillos in detail, including their physical characteristics, habitat, diet,<br /> behavior, and conservation status.<br /><br />

                    <h3>Physical Characteristics:</h3><br />
                    Armadillos have a unique physical appearance, which makes them stand out from other<br /> mammals. They are characterized by their hard, bony plates that cover their backs, head,<br /> legs, and tail. These plates are made of keratin, the same material that makes up<br /> human hair and nails. The armor provides excellent protection against predators and<br /> helps armadillos to dig burrows in the soil.<br />

                    Armadillos have long, pointy snouts that they use to sniff out insects and other small<br /> animals. They also have strong, sharp claws that enable them to dig burrows and search<br /> for food. The claws are well adapted for digging, and they can quickly burrow<br /> through hard soil and roots.<br /><br />

                    <h3>Habitat:</h3><br />
                    Armadillos are native to the Americas and can be found from the southern United States to<br /> Argentina. They are mostly found in warm, humid regions, such as grasslands,<br /> forests, and deserts. Armadillos are excellent diggers and can dig deep burrows in the <br />soil, where they can rest during the day and come out to forage at night.<br /><br />

                    <h3>Diet:</h3><br />
                    Armadillos are omnivores and eat a wide range of foods, including insects, small <br />vertebrates, plants, and fruits. They use their keen sense of smell to locate food, and <br />their long tongues can reach deep into insect nests to catch prey. They are <br />particularly fond of termites, which they can detect from a considerable distance away.<br /><br />

                    <h3>Behavior:</h3><br />
                    Armadillos are primarily solitary creatures and are most active at night. They spend most<br /> of their day resting in their burrows, which they can dig up to six feet deep.<br /> Armadillos are excellent swimmers and can hold their breath for several minutes,<br /> which helps them cross streams and rivers.<br />

                    Armadillos are also known for their unique behavior of leaping into the air when startled.<br /> This behavior is known as "jumping" or "startle reflex," and it is thought to be an<br /> adaptation to avoid predators. When threatened, armadillos jump straight up into<br /> the air, often up to three or four feet high. This sudden movement can startle<br /> predators and give the armadillo time to escape.<br /><br />

                    <h3>Conservation Status:</h3><br />
                    Armadillos are not currently considered endangered, although some species are under threat<br /> due to habitat loss, hunting, and roadkill. In some regions, armadillos are<br /> hunted for their meat, which is considered a delicacy. In addition, armadillos are <br />frequently killed on roads due to their habit of crossing roads in search of food.<br />

                    To conserve armadillos, it is essential to protect their natural habitats and minimize the <br />impact of human activities. Some organizations are working to educate people about <br />the importance of armadillos in their ecosystems and to promote sustainable hunting<br /> practices.<br /><br />

                    <h3>Conclusion:</h3><br />
                    Armadillos are unique and fascinating creatures that have adapted well to their <br />environments. They are well-known for their armored shells, keen sense of smell, and <br />exceptional digging abilities. Despite some threats to their populations, armadillos are<br /> not currently considered endangered. By taking steps to conserve their habitats<br /> and promote sustainable practices, we can ensure that these remarkable<br /> animals continue to thrive in the wild.<br />


                </p><br /><br />


                <h3>Related Animals:-</h3>
                <div style={{ display: 'flex' }} className='hlist'>
                    <Link to="/Antelope-detail">1<img src="https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alligator-detail">2<img src="https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Aardvark-detail">3<img src="https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Alpaca-detail">4<img src="https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anaconda-detail">5<img src="https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Anteater-detail">6<img src="https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Elephant-detail">7<img src="https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/Fox-detail">8<img src="https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">9<img src="https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                    <Link to="/">10<img src="https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600" alt="animal image" height={'100px'} width={'150px'} style={{ borderRadius: '5px' }} /></Link>
                </div>
            </div>

            {/* slider code */}

            <div className="slider-container">
                <button className="prev-button" onClick={handlePrev}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/left-arrow_2b05-fe0f.png' />
                </button>
                <div className="slider" ref={sliderRef}>
                    <img src="https://images.pexels.com/photos/14854853/pexels-photo-14854853.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 1" className="slider-image" />
                    <img src="https://images.pexels.com/photos/13465131/pexels-photo-13465131.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 2" className="slider-image" />
                    <img src="https://images.pexels.com/photos/13464965/pexels-photo-13464965.jpeg?auto=compress&cs=tinysrgb&w=600" alt="Image 3" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2019/05/20/18/01/armadillo-4217183__340.jpg" alt="Image 4" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2018/01/05/12/36/devotional-battleship-3062825__340.jpg" alt="Image 5" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2012/02/27/17/08/armadillo-17580__340.jpg" alt="Image 6" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2017/06/26/16/20/animal-2444225__340.jpg" alt="Image 7" className="slider-image" />
                    <img src="https://cdn.pixabay.com/photo/2020/03/02/18/41/zoo-4896542__340.jpg" alt="Image 8" className="slider-image" />
                </div>
                <button className="next-button" onClick={handleNext}>
                    <img src='https://em-content.zobj.net/thumbs/120/openmoji/338/right-arrow_27a1-fe0f.png' />
                </button>

            </div>

        </>
    );
}
export default Armadillo;