import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
function Navbar() {
  return (
    <div className='nav'>
      <div className='logo'>
        <img src={process.env.PUBLIC_URL + `/logo1.jpg`} alt='img' width={'100px'} height={'100px'}/>
      </div>
      <div>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
          {/* <li>
            <Link to="/contact">contact Us</Link>
          </li> */}
          <li>
            <Link to="/AProductsContext">Animals</Link>
          </li>
          <li>
            <Link to="/BProductsContext">Birds</Link>
          </li>
          <li>
            <Link to="/AAProductsContext">Aquatic Animals</Link>
          </li>
        </ul></div>
    </div>
  );
}

export default Navbar;