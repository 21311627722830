import React from 'react';
function Contact(){
    return(
        <>
        <h1>Contact wali screen</h1>
        <script async="async" data-cfasync="false" src="//pl20018620.highrevenuegate.com/b30c02daf4de89ad7e30774d6fab8ee0/invoke.js">ads</script>
        <div id="container-b30c02daf4de89ad7e30774d6fab8ee0">ads</div>
</>
        
    );
}
export default Contact;