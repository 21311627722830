import React, { createContext, useState } from "react";
import { Helmet } from 'react-helmet-async';
import Products from "./AProducts";
import Antelope from './voices/antelope.wav';
import Alligator from './voices/alligator.wav';
import Alpaca from './voices/alpaca.wav';
import Anaconda from './voices/anaconda.mp3';
import Anteater from './voices/Anteater.mp3';
import elephant from './voices/elephant.mp3';
import Fox from './voices/fox.mp3';

export const ProductsContext = createContext();

const AnimalsContextProvider = (props) => {
    const [products] = useState([
        {
            id: 1,
            name: 'Antelope',
            image: "https://images.pexels.com/photos/1130434/pexels-photo-1130434.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: Antelope,

        },
        {
            id: 2,
            name: 'Alligator',
            image: "https://cdn.pixabay.com/photo/2017/10/05/21/30/crocodile-2821093__340.jpg",
            audio: Alligator,

        },
        {
            id: 3,
            name: 'Armadillo',
            image: "https://images.pexels.com/photos/12762129/pexels-photo-12762129.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: "",

        },
        {
            id: 4,
            name: 'Aardvark',
            image: "https://media.istockphoto.com/id/1326179601/photo/once-in-a-lifetime-juvenile-aardvark-running-wild.jpg?b=1&s=170667a&w=0&k=20&c=IlMsYI_y054tepMMf3-2GneobYUFcSymXXOMXKTVuwU=",
            audio: "",

        },
        {
            id: 5,
            name: 'Alpaca',
            image: "https://cdn.pixabay.com/photo/2021/11/09/18/51/llama-6782140__340.jpg",
            audio: Alpaca,

        },
        {
            id: 6,
            name: 'Anaconda',
            image: "https://media.istockphoto.com/id/1082603570/photo/yellow-anaconda-laying-on-the-ground.jpg?b=1&s=170667a&w=0&k=20&c=FQXE7lGP6OFdbHJcXBG2l_67Uz6svUga3VyapRlQVqE=",
            audio: Anaconda,

        },
        {
            id: 7,
            name: 'Anteater',
            image: "https://cdn.pixabay.com/photo/2023/03/02/10/33/monotreme-7825038__340.jpg",
            audio: Anteater,

        },
        {
            id: 8,
            name: 'Elephant',
            image: "https://cdn.pixabay.com/photo/2017/10/20/10/58/elephant-2870777__340.jpg",
            audio: elephant,

        },
        {
            id: 9,
            name: 'Fox',
            image: "https://cdn.pixabay.com/photo/2020/03/01/15/30/fox-4893199__340.jpg",
            audio: Fox,

        },
        {
            id: 10,
            name: 'Baboon',
            image: "https://cdn.pixabay.com/photo/2018/09/05/20/24/baboon-3657030_640.jpg",
            audio: Fox,

        },
        {
            id: 11,
            name: 'Badger',
            image: "https://images.pexels.com/photos/11882072/pexels-photo-11882072.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: Fox,

        },
        {
            id: 12,
            name: 'Bactrian Camel',
            image: "https://cdn.pixabay.com/photo/2021/09/26/11/56/animal-6657488_640.jpg",
            audio: Fox,

        },
        {
            id: 13,
            name: 'Barbary Ape',
            image: "https://cdn.pixabay.com/photo/2018/11/13/15/25/barbary-ape-3813278_640.jpg",
            audio: Fox,

        },
        {
            id: 14,
            name: 'Basilisk Lizard',
            image: "https://images.pexels.com/photos/12800361/pexels-photo-12800361.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: Fox,

        },
        {
            id: 15,
            name: 'Beaver',
            image: "https://cdn.pixabay.com/photo/2020/02/02/20/05/beaver-4814029_640.jpg",
            audio: Fox,

        },
        {
            id: 16,
            name: 'Bengal Tiger',
            image: "https://images.pexels.com/photos/12436501/pexels-photo-12436501.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: Fox,

        },
        {
            id: 17,
            name: 'Bighorn Sheep',
            image: "https://media.gettyimages.com/id/97473765/photo/close-up-of-brown-bighorn-sheep.jpg?s=612x612&w=0&k=20&c=lXX5DF29F6KAUg5vVTNZ1h23N36wuSAmrtRacWJ4CvU=",
            audio: Fox,

        },
        {
            id: 18,
            name: 'Bison',
            image: "https://st4.depositphotos.com/1258245/21512/i/450/depositphotos_215123174-stock-photo-european-bison-natural-habitat.jpg",
            audio: Fox,

        },
        {
            id: 19,
            name: 'Bear',
            image: "https://cdn.pixabay.com/photo/2016/03/27/18/10/bear-1283347_640.jpg",
            audio: Fox,

        },
        {
            id: 20,
            name: 'Black Mamba',
            image: "https://images.pexels.com/photos/7848084/pexels-photo-7848084.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: Fox,

        },
        {
            id: 21,
            name: 'Black Rhinoceros',
            image: "https://cdn.pixabay.com/photo/2016/12/09/15/53/rhino-1895146_640.jpg",
            audio: Fox,

        },
        {
            id: 22,
            name: 'Black Widow Spider',
            image: "https://cdn.pixabay.com/photo/2016/09/29/22/21/arachnophobia-1703991_1280.jpg",
            audio: Fox,

        },
        {
            id: 23,
            name: 'Bobcat',
            image: "https://st3.depositphotos.com/5906210/16216/i/450/depositphotos_162160046-stock-photo-bobcat-lynx-rufus-californicus-resting.jpg",
            audio: Fox,

        },
        {
            id: 24,
            name: 'Bonobo',
            image: "https://cdn.pixabay.com/photo/2017/03/13/12/24/bonobo-2139563_640.jpg",
            audio: Fox,

        },
        {
            id: 25,
            name: 'Bongo',
            image: "https://cdn.pixabay.com/photo/2022/10/17/12/18/eastern-bongo-7527476_640.jpg",
            audio: Fox,

        },
        {
            id: 26,
            name: 'Burmese Python',
            image: "https://cdn.pixabay.com/photo/2019/11/22/21/27/burmese-python-4645805_640.jpg",
            audio: "",

        },
        {
            id: 27,
            name: 'Cheetah',
            image: "https://images.pexels.com/photos/2017490/pexels-photo-2017490.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: "",

        },
        {
            id: 28,
            name: 'Chipmunk',
            image: "https://images.pexels.com/photos/751829/pexels-photo-751829.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: '',

        },
        {
            id: 29,
            name: 'Cougar',
            image: "https://cdn.pixabay.com/photo/2017/10/02/08/48/cougar-2808198_640.jpg",
            audio: "",
        },
        {
            id: 30,
            name: 'Cat',
            image: "https://images.pexels.com/photos/2558605/pexels-photo-2558605.jpeg?auto=compress&cs=tinysrgb&w=600",
            audio: "",
        },
        {
            id: 31,
            name: 'Cow',
            image: "https://st.depositphotos.com/1765488/3582/i/450/depositphotos_35820601-stock-photo-herd-of-young-calves-drinking.jpg",
            audio: "",
        },
        {
            id: 32,
            name: 'Chimpanzee',
            image: "https://st.depositphotos.com/1381640/1213/i/450/depositphotos_12139854-stock-photo-chimpanzee.jpg",
            audio: "",
        },
        {
            id: 33,
            name: 'Coyote',
            image: "https://st.depositphotos.com/2014545/1982/i/450/depositphotos_19820983-stock-photo-coyote-strolling.jpg",
            audio: "",
        },
        {
            id: 34,
            name: 'Camel',
            image: "https://static5.depositphotos.com/1021682/418/i/450/depositphotos_4180937-stock-photo-empty-quarter-camel.jpg",
            audio: "",
        },
        {
            id: 35,
            name: 'Caribou',
            image: "https://st2.depositphotos.com/1746771/7548/i/450/depositphotos_75484131-stock-photo-male-caribou-grazing.jpg",
            audio: "",
        },
        {
            id: 36,
            name: 'Capybara',
            image: "https://st.depositphotos.com/2081781/2539/i/450/depositphotos_25398533-stock-photo-capybara-relaxed-hydrochoerus-hydrochaeris.jpg",
            audio: "",
        },
        {
            id: 37,
            name: 'Civet',
            image: "https://st3.depositphotos.com/17800970/33601/i/450/depositphotos_336013408-stock-photo-african-civet-civettictis-civetta-large.jpg",
            audio: "",
        },
        {
            id: 38,
            name: 'Caiman',
            image: "https://st3.depositphotos.com/2777205/12856/i/450/depositphotos_128562480-stock-photo-close-up-of-yacare-caiman.jpg",
            audio: "",
        },
        {
            id: 39,
            name: 'Collared Peccary',
            image: "https://st2.depositphotos.com/1009587/6994/i/450/depositphotos_69948919-stock-photo-collared-peccary-walking.jpg",
            audio: "",
        },
        {
            id: 40,
            name: 'Common Genet',
            image: "https://st5.depositphotos.com/1830989/64660/i/450/depositphotos_646607908-stock-photo-common-genet-genetta-genetta-branch.jpg",
            audio: "",
        },
        {
            id: 41,
            name: 'Common Eland',
            image: "https://st5.depositphotos.com/22386024/64472/i/450/depositphotos_644721614-stock-photo-common-eland-bull-red-billed.jpg",
            audio: "",
        },
        {
            id: 42,
            name: 'Common Marmoset',
            image: "https://st3.depositphotos.com/1006961/19171/i/450/depositphotos_191714504-stock-photo-common-marmoset-callithrix-jacchus-small.jpg",
            audio: "",
        },
        {
            id: 43,
            name: 'Common Warthog',
            image: "https://st3.depositphotos.com/7508852/15031/i/450/depositphotos_150316200-stock-photo-common-warthog-side-view.jpg",
            audio: "",
        },
        {
            id: 44,
            name: 'Coati',
            image: "https://st2.depositphotos.com/1902695/8477/i/450/depositphotos_84777506-stock-photo-coati-nasua-nasua-racoon-portrait.jpg",
            audio: "",
        },
        {
            id: 45,
            name: 'Dog',
            image: "https://st4.depositphotos.com/12985790/24533/i/450/depositphotos_245331962-stock-photo-funny-golden-retriever-dog-resting.jpg",
            audio: "",
        },
        {
            id: 46,
            name: 'Donkey',
            image: "https://st2.depositphotos.com/3669029/10984/i/450/depositphotos_109846632-stock-photo-donkey-equus-africanus-asinus.jpg",
            audio: "",
        },
        {
            id: 47,
            name: 'Dingo',
            image: "https://st.depositphotos.com/2047341/4943/i/450/depositphotos_49436339-stock-photo-australian-dingo.jpg",
            audio: "",
        },
        {
            id: 48,
            name: 'Dik-dik',
            image: "https://cdn.pixabay.com/photo/2015/09/25/21/27/dik-dik-958208_1280.jpg",
            audio: "",
        },
        {
            id: 49,
            name: 'Desert tortoise',
            image: "https://st2.depositphotos.com/1016811/6147/i/450/depositphotos_61475257-stock-photo-large-tortoise-walking-in-the.jpg",
            audio: "",
        },
        {
            id: 50,
            name: 'Domestic rabbit',
            image: "https://st4.depositphotos.com/15076092/30932/i/450/depositphotos_309321970-stock-photo-dutch-rabbit-brown-white.jpg",
            audio: "",
        }



    ]);

    return (
        <>
            <Helmet>
                <title>Animals</title>
                <meta name='description' content='THE NATURAL WORLD Animals page'></meta>
                <link rel='canonical' href='/AProductsContext' />
            </Helmet>
            <ProductsContext.Provider value={{ products: [...products] }}>
                {props.children}
                <Products />
            </ProductsContext.Provider>
        </>


    )
}

export default AnimalsContextProvider;


